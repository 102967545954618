<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <ContentMod />
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from "../components/HeaderMod.vue";
import FooterMod from "../components/FooterMod.vue";
import ContentMod from '../components/ContentMod.vue'

export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  components: {
    HeaderMod,
    ContentMod,
    FooterMod,
  },
};
</script>
