<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <div class="m-pages-background">
      <h3 class="title">关于我们<span class="f-block-line"></span></h3>
    </div>
    <!-- content -->
    <div class="m-about-content">
      <div class="content-company">
        <h5 class="title">COMPANY INTRODUCTION</h5>
        <h6 class="sub-title">企业介绍<span class="f-block-line"></span></h6>
        <div class="main box">
          <div class="content-text fl">
            <p>广州布莱特教育科技有限公司是一家专注于留学生缴费的互联网公司，服务范围几乎覆盖所有留学院校的留学国家。</p>
            <p>2022年布菜特与广州合利宝支付科技有限公司共同推出了【留学e汇】这个缴费平台。并先后与广外留学服务中心、罗斯德全球高中、新世界教育集团（樱花国际）等国际学校和大型机构达成战略合作。</p>
            <p>2023年3月，【留学e汇】签约发布会正式在广州举办，并邀请了各界人士参加。同月，布莱特教育科技加入广东省国际教育保进会，成为常务理事单位。</p>
            <p>我们以人为本，坚持科技创新，紧跟时代步伐，围绕客户需求加大研究投入，厚积薄发，继续推动海外支付领域科技进步。</p>
          </div>
          <img class="fr" src="../assets/about_company.png" style="margin-top: 35px;" />
        </div>
      </div>

      <div class="content-about">
        <h5 class="title">ABOUT FOUNDER</h5>
        <h6 class="sub-title">创始人介绍<span class="f-block-line"></span></h6>
        <div class="main box">
          <img class="fl" src="../assets/about_founder.png" />
          <div class="content-text fr">
            <p class="f-text-bold">冯力 | 布莱特创始人、总经理</p>
            <p>毕业于中山大学，在工商银行、光大银行以及平安银行工作超过二十年，在银行科技部、私人部、公司业务部等多个岗位工作过。在银行工作期间，注意到了留学生来银行进行留学缴费和国际汇款时的各种问题，致力于创办一个为留学生服务的金融平台。</p>
            <p>2019年离开银行后，创立了布菜特教育科技公司，建立了【留学e汇】这个专注于留学生的缴费费平台，用于解决学生在留学过程中所遇到的各类资金支付问题。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="m-about-footer">
      <h5 class="title">CORPORATE CULTURE</h5>
      <h6 class="sub-title">企业文化<span class="f-block-line"></span></h6>
      <ul>
        <li>
          <div class="icon">
            <i class="u-icon-corporate01"></i>
          </div>
          <p class="tips">愿景</p>
          <p class="sub-tips">中国领先的留学金融服务平台</p>
        </li>
        <li>
          <div class="icon">
            <i class="u-icon-corporate02"></i>
          </div>
          <p class="tips">使命</p>
          <p class="sub-tips">坚持科技创新、推动海外支付领域科技进步</p>
        </li>
        <li>
          <div class="icon">
            <i class="u-icon-corporate03"></i>
          </div>
          <p class="tips">精神</p>
          <p class="sub-tips">精诚奋进、合作共赢</p>
        </li>
        <li>
          <div class="icon">
            <i class="u-icon-corporate04"></i>
          </div>
          <p class="tips">价值观</p>
          <p class="sub-tips">担当、激情、开放、信任</p>
        </li>
      </ul>
    </div>
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from "../components/FooterMod.vue";

export default {
  name: 'AboutUs',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    return {
    };
  },
  methods: {
  }

}
</script>
<style scoped>
.m-pages-background {
  margin-bottom: 126px;
  height: 200px;
  background: url("../assets/about_top.jpg") no-repeat center;
  text-align: center;
}
.m-pages-background .title {
  padding-top: 75px;
  font-size: 36px;
  color: #fff;
}
.m-about-content {
  color: #535353;
}
.m-about-content .title {
  font-size: 30px;
  text-align: center;
}
.m-about-content .sub-title {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}
.m-about-content p {
  margin-top: 30px;
  font-size: 16px;
}
.m-about-content .box {
  overflow: hidden;
}
.content-about {
  padding-top: 117px;
  background-color: #F0F8FD;
}
.content-text {
  padding-bottom: 130px;
}
.content-company .content-text {
  width: 480px;
}
.content-about .content-text {
  width: 750px;
}
.f-text-bold {
  font-weight: 700;
}
.f-block-line {
  display: block;
  margin: 0 auto;
  width: 50px;
  border-bottom: 4px solid #008CD6;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.m-about-footer {
  height: 700px;
  background: url("../assets/about_bottom.jpg") no-repeat center;
  color: #fff;
}
.m-about-footer .title {
  padding-top: 160px;
  font-size: 30px;
  text-align: center;
}
.m-about-footer .sub-title {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}
.m-about-footer ul {
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
}
.m-about-footer li {
  text-align: center;
}
.m-about-footer .icon {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #008CD6;
}
.m-about-footer .tips {
  margin: 10px 0 4px;
  font-size: 26px;
  font-weight: 700;
}
.m-about-footer .sub-tips {
  font-size: 14px;
}
.m-about-footer .icon i {
  margin-top: 30px;
  height: 60px;
  display: inline-block;
}
.u-icon-corporate01 {
  width: 62px;
  background: url("../assets/icon_corporate01.png") no-repeat;
}
.u-icon-corporate02 {
  width: 59px;
  background: url("../assets/icon_corporate02.png") no-repeat;
}
.u-icon-corporate03 {
  width: 66px;
  background: url("../assets/icon_corporate03.png") no-repeat;
}
.u-icon-corporate04 {
  width: 64px;
  background: url("../assets/icon_corporate04.png") no-repeat;
}
</style>