<template>
  <div class="m-header">
    <div class="main">
      <!-- logo -->
      <router-link to="/" class="u-logo" tiritle="留学e汇"></router-link>
      <!-- navigation -->
      <ul class="u-navigation">
        <li>
          <router-link to="/">首页</router-link>
        </li>
        <li>
          <el-popconfirm
            v-if="isCurrentHomePage"
            class="u-pay-tips"
            popper-class="u-pay-tips"
            confirm-button-text='知道了'
            cancel-button-text=' '
            :hide-icon="true"
            title="请在首页搜索院校名称，进入缴费页面"
          >
            <a slot="reference">我要缴费</a>
          </el-popconfirm>
          <router-link v-else to="/">我要缴费</router-link>
        </li>
        <li>
          <router-link to="/NewsList">新闻中心</router-link>
        </li>
        <li>
          <router-link to="/AboutUs">关于我们</router-link>
        </li>
        <li>
          <span> <i class="u-icon-china"></i>中国 </span>
        </li>
        <li>
          <!-- login & register -->
          <div v-if="userName" :class="{'f-hover-username': hasclickUserName }">
            <span class="userNavName" @click="handleUserName(hasclickUserName)">
              {{ userName }}
              <i v-if="hasclickUserName" class="el-icon-caret-top"></i>
              <i v-else class="el-icon-caret-bottom"></i>
            </span>
            <ul class="userNavList">
              <li>
                <router-link to="/userCenter">个人中心</router-link>
              </li>
              <li>
                <router-link to="/HistoryList">历史订单</router-link>
              </li>
              <li>
                <router-link to="/setpassword">修改密码</router-link>
              </li>
              <li>
                <span class="f-login-out" @click="loginOut">退出账号</span>
              </li>
            </ul>
          </div>
          <router-link v-else to="/LoginRegister" class="u-login-reg"> 注册/登录 </router-link>
        
        </li>
      </ul>
    </div>
    <PopTips />
  </div>
</template>

<script>
import PopTips from "./PopTips.vue";

export default {
  name: "HeaderMod",
  props: {
    msg: String,
  },
  components: {
    PopTips,
  },
  data() {
    return {
      userName: '',
      hasclickUserName: false,
      isCurrentHomePage: false,
    }
  },
  mounted() {
    
    this.getUserName()

    document.addEventListener('click', e => {
      if (e.target.nodeName == 'DIV' || e.target.nodeName == 'UL') {
        this.hasclickUserName = false;

      }
    });

    this.checkCurrentPage()
    
  },
  methods: {
    getUserName() {
      this.userName = localStorage.getItem('nickname');
    },
    loginOut() {
      // 退出登录
      localStorage.removeItem('token')
      localStorage.removeItem('nickname')
      this.$router.push('/LoginRegister');
    },
    handleUserName(params) {
      // 点击"用户名"出现下拉内容
      this.hasclickUserName = !params
    },
    checkCurrentPage() {
      // 当前页面是否为"首页"，是的话则提示，否则点击可跳到首页
      const url = location.hash;
      this.isCurrentHomePage = (url == '#/') || false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.m-header {
  height: 80px;
  font-size: 16px;
  background-color: #008cd6;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
  }
  .u-logo {
    float: left;
    margin-top: 16px;
    width: 186px;
    height: 45px;
    background: url("../assets/logo@2x.png") no-repeat;
    background-size: 100%;
  }
  .u-navigation {
    float: right;
    line-height: 80px;
    li {
      float: left;
      margin: 0 30px;
    }
    .u-login-reg {
      display: inline-block;
      margin-top: 16px;
      width: 130px;
      height: 44px;
      line-height: 44px;
      border: 1px solid #fff;
      border-radius: 5px;
      text-align: center;
    }
  }
  .u-icon-china {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    background: url("../assets/china@2x.png") no-repeat;
  }
  .userNavList {
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 10;
    width: 130px;
    line-height: 42px;
    background-color: #fff;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    color: #616161;
    font-size: 16px;
    text-align: center;
    a {
      color: #616161;
    }
    li {
      float: none;
      margin: 0;
      border-bottom: 1px solid #D2D2D2;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .f-hover-username {
    position: relative;
    .userNavList {
      display: block;
    }
  }
  .f-login-out {
    cursor: pointer;
  }
}
</style>
<style>
.u-pay-tips .el-popconfirm__action {
  display: none;
}
.u-pay-tips .el-popconfirm__main {
  padding: 10px
}
.u-pay-tips {
  cursor: pointer;
}
.el-tooltip__popper {
  max-width: 600px!important;
}
.userNavName {
  display: block;
  max-width: 168px;
  white-space: nowrap;
}
</style>
