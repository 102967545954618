import { render, staticRenderFns } from "./LoginReg.vue?vue&type=template&id=29197bcb&scoped=true&"
import script from "./LoginReg.vue?vue&type=script&lang=js&"
export * from "./LoginReg.vue?vue&type=script&lang=js&"
import style0 from "./LoginReg.vue?vue&type=style&index=0&id=29197bcb&prod&lang=scss&scoped=true&"
import style1 from "./LoginReg.vue?vue&type=style&index=1&id=29197bcb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29197bcb",
  null
  
)

export default component.exports