<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <div class="m-content main">
      <h3 class="news-title" v-if="newsInfoData.title">{{ newsInfoData.title }}<span class="f-block-line"></span></h3>
      <div class="news-content" v-html="newsInfoData.content"></div>
    </div>

  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import { getSinglePageInfo } from '@/servers/news.js'

export default {
  name: 'NewsInfo',
  props: {
    msg: String
  },
  components: {
    HeaderMod
  },
  data() {
    return {
      newsInfoData: {},
    }
  },
  mounted() {
    this.getSinglePageInfoData()
  },
  methods: {
    getSinglePageInfoData() {
      // 获取新闻列表
      const id = this.getUrlParams('id')
      getSinglePageInfo({
        id
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.newsInfoData = res.data
        }
      });
    },
    getUrlParams(paramName) {
      let url = window.location.href;
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
  }

}
</script>
<style scoped>
.news-title {
  margin-bottom: 25px;
  padding-top: 25px;
  font-size: 28px;
  text-align: center;
}
.news-content {
  line-height: 28px;
}
.f-block-line {
  display: block;
  margin: 0 auto;
  width: 50px;
  border-bottom: 4px solid #008CD6;
}
</style>