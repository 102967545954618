<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
      <!-- 进度条 -->
      <el-steps :active="3" align-center class="u-steps-tips">
        <div class="main" style=" display: flex; margin: 50px auto 0; width: 1400px;">
          <el-step title="填写信息" icon="el-icon-success"></el-step>
          <el-step title="上传资料" icon="el-icon-success"></el-step>
          <el-step title="缴费支付" icon="el-icon-success"></el-step>
        </div>
      </el-steps>
      <!-- 填写信息 -->
      <div class="m-content main">
        <div class="payment-order-number">
          <span class="name">订单编号</span>
          <span class="text">{{ getUrlParams('orderNumber') }}</span>
        </div>

        <div class="u-payment-content">
          <h5 class="payment-order-title">1、付款信息</h5>
          <ul class="payment-order-content">
            <li>
              <span class="box-name">收款方</span>
              <span class="box-content">{{ orderData.college_name }}</span>
            </li>
            <li>
              <span class="box-name">收款账户名</span>
              <span class="box-content">{{ orderData.account_name }}</span>
            </li>
            <li>
              <span class="box-name">收款账户号</span>
              <span class="box-content">{{ orderData.account_num }}</span>
            </li>
            <li v-if="orderData.swift_code">
              <span class="box-name">SWIFT Code</span>
              <span class="box-content">{{ orderData.swift_code }}</span>
            </li>
            <li v-if="orderData.bsb_number">
              <span class="box-name">BSB Number</span>
              <span class="box-content">{{ orderData.bsb_number }}</span>
            </li>
            <li>
              <span class="box-name">汇款金额</span>
              <span class="box-content">{{ orderData.remit_amount_str }}</span>
            </li>
          </ul>
          <h5 class="payment-order-title">2、付款方式</h5>
          <el-tabs
            v-model="activeName" @tab-click="handleClickTab">
            <!-- 多tab时使用 :stretch="true"居中 -->
            <el-tab-pane label="转账支付" name="first">
              <i class="u-icon-tab120"></i>
            </el-tab-pane>
            <!-- "微信支付"、"支付宝支付"暂不开发 -->
            <!-- <el-tab-pane label="微信支付" name="second"></el-tab-pane>
            <el-tab-pane label="支付宝支付" name="third"></el-tab-pane> -->
          </el-tabs>
          <ul class="payment-order-content">
            <li>
              <span class="box-name">外币金额</span>
              <span class="box-content">{{ orderData.remit_amount_str }}</span>
            </li>
            <li>
              <span class="box-name">足额到账服务</span>
              <span class="box-content">{{ orderData.fully_rec_fee }}</span>
            </li>
            <li>
              <span class="box-name">平台服务费</span>
              <span class="box-content">{{ orderData.cross_fee }}</span>
            </li>
            <li>
              <span class="box-name">转账手续费</span>
              <span class="box-content">{{ orderData.transfer_fee }}</span>
            </li>

            <li class="paymeny-tips">
              <span class="box-name f-text-bold">合计金额</span>
              <span class="box-content">{{ orderData.total_amount_str }}</span>
              <el-popover
                placement="bottom-end"
                width="260"
                trigger="hover"
                content="* 未审核通过的订单，将进行全额退款。
                * 未审核通过的订单，将进行全额退款。
                * 已审核通过的订单簿能退款。  
                * 已经汇出的款项，手续费及足额费不退还，剩余资金将按照支付汇率退回。
                * 具体的退款规则以【留学e汇】《留学汇款用户服务协议》项下的内容为准。
                ">
                <el-button slot="reference">退款说明</el-button>
              </el-popover>
              <p class="f-text-tips">
                【请严格按照此金额进行付款】
              </p>
              <p v-if="deadlineTimeText" class="f-text-bold">
                <i class="u-icon-timeout"></i>
                支付剩余时间
                <span class="box-name">{{ deadlineTimeText }}</span>
              </p>
            </li>
            
            <li>
              <span class="f-text-bold">转账信息</span>
              <span class="f-text-tips">(受央行监管的备付金账户，资金安全有保障)</span>
            </li>
            <li>
              <span class="box-name">收款户名</span>
              <span class="box-content f-name-context">{{ orderData.pay_account_name }}</span>
              <el-button type="primary" plain size="mini" @click="handleCopy(orderData.pay_account_name)">复制</el-button>
            </li>
            <li>
              <span class="box-name">收款账号</span>
              <span class="box-content f-name-context">{{ orderData.pay_account_num }}</span>
              <el-button type="primary" plain size="mini" @click="handleCopy(orderData.pay_account_num)">复制</el-button>
            </li>
            <li>
              <span class="box-name">收款银行</span>
              <span class="box-content f-name-context">{{ orderData.pay_bank_name}}</span>
              <el-button type="primary" plain size="mini" @click="handleCopy(orderData.pay_bank_name)">复制</el-button>
            </li>
            <li>
              <span class="box-name">开户地</span>
              <span class="box-content f-name-context">{{ orderData.pay_bank_place }}</span>
              <el-button type="primary" plain size="mini" @click="handleCopy(orderData.pay_bank_place)">复制</el-button>
            </li>
            <li>
              <span class="box-name">开户网点</span>
              <span class="box-content f-name-context">{{ orderData.pay_bank_branch }}</span>
              <el-button type="primary" plain size="mini" @click="handleCopy(orderData.pay_bank_branch)">复制</el-button>
            </li>
            <li>
              <span class="box-name">备注要求</span>
              <span class="box-content f-name-context">{{ orderData.pay_bank_remarks }}</span>
              <el-button type="primary" plain size="mini" @click="handleCopy(orderData.pay_bank_remarks)">复制</el-button>
              </li>
            <li class="payment-tips-box">
              <div>
                <p>
                  <span class="f-text-bold">订单信息</span>
                </p>
                <p>
                  <span class="box-name">订单编号</span>
                  <span class="box-content">{{ orderData.order_number}}</span>
                </p>
                <p>
                  <span class="box-name">订单金额</span>
                  <span class="box-content f-payment-num">{{ orderData.total_amount }}</span>
                </p>
                <p>
                  <span class="box-name">下单时间</span>
                  <span class="box-content">{{ orderData.add_time }}</span>
                </p>
                <p>
                  <router-link target="_blank" to="/PreviewPage?id=8" class="tips">查看转账指引</router-link> |
                  <span class="tips" @click="handleShowBandNote">各银行大额转账注意点</span>
                </p>
              </div>
            </li>
          </ul>
          <div v-if="orderData.order_status == 1" class="u-info">
            <el-button @click="handleCallbackPage">返回修改</el-button>
            <el-button type="primary" @click="handleUpdateOrderStatus">本人付款完毕</el-button>
          </div>
        </div>
      </div>

      <el-dialog
        :visible.sync="dialogVisible"
        width="900px"
        center
        class="u-banknotes-pop"
      >
        <div class="tips-pop-content">
          <el-table
            :data="noteTableData"
            :span-method="objectSpanMethod"
            border>
            <el-table-column
              prop="name"
              label="银行名称">
            </el-table-column>
            <el-table-column
              prop="note"
              label="大额转账注意点">
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import { getPayOrderInfo, updateOrderStatus } from '@/servers/order.js'

export default {
  name: 'PaymentForm',
  props: {
    msg: String
  },
  components: {
    HeaderMod
  },
  data() {
    return {
      activeName: 'first',
      orderData: {},
      dialogVisible: false,
      hasPayStatus: false,
      deadlineTimeText: '',
      deadlineTime: 0,
      noteTableData: [
        {
          name: '建设银行',
          note: '-',
        },{
          name: '招商银行',
          note: '-',
        },{
          name: '交通银行',
          note: '-',
        },{
          name: '工商银行',
          note: '-',
        },{
          name: '农业银行',
          note: '-',
        },{
          name: '中信银行',
          note: '-',
        },{
          name: '北京银行',
          note: '-',
        },{
          name: '华夏银行',
          note: '-',
        },{
          name: '光大银行',
          note: '-',
        },{
          name: '民生银行',
          note: '银行要求：大额转账时需要将"收款方账号"写在转账备注中',
        },{
          name: '广发银行',
          note: '银行要求：大额转账时需要将"收款方账号"写在转账备注中',
        },{
          name: '平安银行',
          note: '银行要求：大额转账时需要将"收款方账号"写在转账备注中',
        },{
          name: '中国银行',
          note: '银行要求：转账金额5元以上',
        },
      ],
      fileList: [{url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    };
  },
  mounted() {
    this.getPayOrderInfoData()
  },
  methods: {
    getPayOrderInfoData() {
      // 获取订单信息
      getPayOrderInfo({
        order_number: this.getUrlParams('orderNumber')
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.orderData = res.data || {}
          this.deadlineTime = res.data.deadline_time
          this.setTimeOut()
        }

      });
    },
    handleClickTab(tab, event) {
      // 切换tab
      console.log(tab, event)
    },
    handleUpdateOrderStatus() {
      // 本人付款完毕
      updateOrderStatus({
        order_number: this.orderData.order_number,
        type: 1 // 1 已支付 2取消
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$router.push('/userCenter');
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
  
      });
    },
    handleShowBandNote() {
      // 查看"各银行大额转账注意点"
      this.dialogVisible = true
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 合并单元格
      console.log(row, column, rowIndex, columnIndex)
      if (columnIndex === 0) {
        if (rowIndex === 10) {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
        
      }
    },
    getUrlParams(paramName) {
      let url = decodeURIComponent(window.location.href);
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
    handleCallbackPage() {
      // 返回修改
      const collegeId = this.getUrlParams('collegeId');
      const orderNumber = this.getUrlParams('orderNumber');
      this.$router.push(`/CollegeInfoForm?collegeId=${collegeId}&collegeName=${this.orderData.college_name}&englishName=${this.orderData.account_name}&orderNumber=${orderNumber}&orderType=3`); // order_type: 1新下单 2再次缴费 3编辑订单信息
    },
    countDown() {
      const endTime = this.deadlineTime * 1000;
      const nowTime = Date.parse(new Date().toString());
      let maxTime = (endTime - nowTime) / 1000 

      if (maxTime >= 0) {
        
        let hour = Math.floor(maxTime / 60 / 60 % 24);
        if (hour < 9) {
          hour = hour ? '0' + hour : '0'
        }

        let min = Math.floor(maxTime / 60 % 60);
        if (min < 9) {
          min = min ? '0' + min : '0'
        }

        let sec = Math.floor(maxTime % 60);
        if (sec < 9) {
          sec = sec ? '0' + sec : '0'
        }
        
        this.deadlineTimeText = ` ${hour}时 ${min}分 ${sec}秒 `
        maxTime --
      } else {
        clearInterval(this.deadlineTimeText);
      }

    },
    setTimeOut() {
      setInterval( () => {
        this.deadlineTime -= 1
        this.countDown();
      }, 1000)
    },
    handleCopy(data) {
      // 复制
      const copyContent = data;
      const inputTag = document.createElement('input');
      inputTag.value = copyContent;
      document.body.appendChild(inputTag);
      inputTag.select();
      const copyResult = document.execCommand('copy');
      document.body.removeChild(inputTag);
      if (copyResult) {
        this.$message({
          message: '复制成功！',
          type: "success",
        })
      }
    },
  }

}
</script>
<style scoped>
.payment-order-number {
  margin-bottom: 40px;
  height: 56px;
  line-height: 56px;
  padding: 0 20px;
  border: 1px solid #008CD6;
  border-radius: 8px;
  font-size: 16px;
}
.payment-order-number .name {
  margin-right: 75px;
  font-weight: 700;
  color: #008CD6;
}
.payment-order-title {
  margin-bottom: 10px;
  font-size: 20px;
}
.payment-order-content .u-icon-submit {
  margin-top: 8px;
  width: 530px;
  height: 56px;
  font-size: 16px;
  background-color: #008CD6;
}
.payment-order-content {
  position: relative;
  margin-bottom: 44px;
  padding: 20px 30px;
  line-height: 44px;
  border-radius: 8px;
  border: 2px solid #008CD6;
  background-color: #DAEFFE;
  font-size: 16px;
}
.payment-order-content .tips {
  color: #008CD6;
  cursor: pointer;
  text-decoration: underline;
}
.payment-order-content .box-name {
  display: inline-block;
  margin-right: 30px;
  width: 120px;
  color: #008CD6;
}
.payment-order-content .box-content {
  display: inline-block;
  width: 290px;
}
.payment-order-content /deep/ .el-popover__reference-wrapper .el-button {
  border-radius: 6px;
  border-color: #008CD6;
  background-color: #DAEFFE;
  color: #008CD6;
  font-size: 16px;
  font-weight: 400;
}
.payment-order-content /deep/ .el-button--primary.is-plain {
  border-radius: 6px;
  border-color: #008CD6;
  background-color: #DAEFFE;
  color: #008CD6;
  font-size: 14px;
}
.u-payment-content /deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: 400;
  color: #535353;
}
.u-payment-content /deep/ .el-tabs__item.is-active {
  font-weight: 700;
  color: #008CD6;
}
.u-payment-content /deep/ .el-tabs__active-bar {
  background-color: #008CD6;
}
.paymeny-tips {
  border-top: 1px dashed #008CD6;
  border-bottom: 1px solid #008CD6;
}
.paymeny-tips {
  margin: 20px 0;
  padding: 10px 0;
}
.paymeny-tips .f-text-tips{
  margin-top: -16px;
  padding-left: 142px;
}
.f-text-bold {
  font-weight: 700;
}
.f-text-tips {
  padding-left: 88px;
  font-size: 14px;
  color: red;
}
.f-payment-num {
  margin: 24px 0;
  font-size: 28px;
}
.u-icon-timeout {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 24px;
  background: url("../assets/timeout@2x.png") no-repeat;
}
.u-payment-content .u-info {
  font-size: 16px;
  text-align: center;
  color: #313131;
}
.u-payment-content .u-info .el-button {
  margin: 12px 20px 42px;
  width: 300px;
  height: 56px;
  font-size: 16px;
}
.u-payment-content .u-info .el-button--default {
  border: 2px solid #008CD6;
  color: #008CD6;
}
.u-payment-content .u-info .el-button--primary {
  background-color: #008CD6;
  color: #fff;
}
.payment-tips-box {
  position: absolute;
  left: 600px;
  bottom: 18px;
}
.payment-tips-box .box-name {
  margin-right: 0;
  width: 100px;
}
/* .paymeny-tips /deep/ .el-popover {
  font-size: 20px;
} */
.u-banknotes-pop /deep/ .el-table--border th.el-table__cell{
  background-color: #008CD6;
  color: #fff;
  text-align: center;
}
.u-banknotes-pop /deep/ .el-table .el-table__cell {
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}
.u-banknotes-pop /deep/ .el-table_1_column_2 {
  color: #008CD6;
}
.f-name-context {
  width: 310px!important;
  line-height: 17px;
  vertical-align: middle;
}
/deep/ .el-step__head.is-finish,
/deep/ .el-step__title.is-finish,
/deep/ .el-step__head.is-process,
/deep/ .el-step__title.is-process {
  color: #008CD6;
}
</style>