<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <div class="m-content main reset-password-box">
      <h5 class="reset-password-title">忘记密码</h5>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="reset-password-ruleForm">
        <el-form-item prop="phone_number" class="phone">
          <el-select v-model="ruleForm.zone_code" placeholder="+86">
            <el-option
              v-for="item in codeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="ruleForm.phone_number"
            placeholder="请输入手机号码"
          ></el-input>
          <el-button v-if="hasSmsCode" type="info" size="medium" @click="sendSmsCode" disabled>{{smsCodeCount}}秒后重试</el-button>
          <el-button v-else :type="phoneNoPass ? 'info' : 'success'" size="medium" @click="sendSmsCode" :disabled="phoneNoPass">短信验证码</el-button>
        </el-form-item>
        <el-form-item prop="verify_code">
          <el-input v-model="ruleForm.verify_code" placeholder="请输入短信验证码"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="ruleForm.password" placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item prop="confirm_password">
          <el-input type="password" v-model="ruleForm.confirm_password" placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
        <el-form-item class="u-infor">
          <el-button type="text" @click="goRegister('ruleForm')">快速注册</el-button>
          <el-button type="text" @click="goCodeLogin('ruleForm')">验证码登录</el-button>
        </el-form-item>
        
      </el-form>
    </div>
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from '../components/FooterMod.vue'
import { getVerifyCode, forgetPassword } from '../servers/login.js'
import { getZoneList } from '@/servers/index.js'
export default {
  name: 'ResetPassWord',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.confirm_password !== "") {
          this.$refs.ruleForm.validateField("confirm_password");
        }
        callback();
      }
    };
    let validatePassNew = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    // 校验手机格式
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[34578][0-9]\d{8}$/;
      if (!value) {
        this.phoneNoPass = true
        callback(new Error("请输入手机号码"));
      } else if (!reg.test(value)) {
        this.phoneNoPass = true
        callback(new Error("手机号码格式不正确，请检查!"));
      } else {
        this.phoneNoPass = false
        callback();
      }
    };
    // 校验短信验证码
    let checkSmsCode = (rule, value, callback) => {
      let reg =/^\d{4}$/;
      if (!value) {
        callback(new Error("请输入短信验证码"));
      } else if (!reg.test(value)) {
        callback(new Error("短信验证码为四位数字，请检查!"));
      } else {
        callback();
      }
    };
    return {
      phoneNoPass: true,
      hasSmsCode: false,
      smsCodeCount: '',
      timer: null,
      codeOptions: [{
        value: 1,
        label: '+86'
      }],
      ruleForm: {
        phone_number: '',
        verify_code: '',
        password: '',
        confirm_password: ''
      },
      rules: {
        phone_number: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        verify_code: [{ required: true, validator: checkSmsCode, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirm_password: [{ validator: validatePassNew, trigger: 'blur' }],
      }
    }
  },
  created() {
    let SmsCodeEndTime = sessionStorage.getItem('SmsCodeEndTime');
    if (SmsCodeEndTime) {
      this.smsCodeTimeDown(SmsCodeEndTime)
    }
  },
  mounted() {
    this.getZoneListData()
  },
  methods: {
    getZoneListData() {
      // 获取手机区号
      getZoneList().then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          const zoneList = []
          list.forEach( v => {
            zoneList.push({
              value: v.zone,
              label: v.name
            })
          })
          
          this.codeOptions = zoneList

          // 默认先帮用户选择第一个
          this.ruleForm.zone_code = zoneList[0].value;
        }
      });
    },
    sendSmsCode() {
      let clickSmsCodeTime = new Date().getTime() + 60000;
      sessionStorage.setItem('SmsCodeEndTime', JSON.stringify(clickSmsCodeTime));
      
      // 发送验证码
      getVerifyCode({
        zone_code: this.ruleForm.zone_code,
        phone_number: this.ruleForm.phone_number,
        type: 2 // 短信类型 1注册 2忘记密码 3更换手机 4其他
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          this.hasSmsCode = true;
          this.smsCodeTimeDown(clickSmsCodeTime);

        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    smsCodeTimeDown(time) {
      if (!this.timer) {
        this.smsCodeCount = Math.ceil((JSON.parse(time) - new Date().getTime()) /1000);
        this.timer = setInterval( () => {
          if (this.smsCodeCount > 0) {
            this.smsCodeCount --;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            sessionStorage.removeItem('SmsCodeEndTime');
            this.hasSmsCode = false;
          }
        }, 1000)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验成功发起接口请求
          forgetPassword({
            zone_code: this.ruleForm.zone_code,
            phone_number: this.ruleForm.phone_number,
            verify_code: this.ruleForm.verify_code,
            password: this.ruleForm.password,
            confirm_password: this.ruleForm.confirm_password
          }).then( (res) => {
            if (Number(res.code) === 20000) {
              this.$message({
                message: '修改密码成功！' || res.msg,
                type: "success",
              });
              this.$router.push('/LoginRegister');

            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });

        } else {
          console.log("error submit!!");
          this.$message({
            type: 'error',
            message: '表单校验失败，请检查！',
          });
          return false;
        }
      });
    },
    goRegister() {
      // 跳转至注册页面
      this.$router.push('/LoginRegister');
    },
    goCodeLogin() {
      // 跳转至验证码登录页面
      this.$router.push('/LoginRegister');
    }
  }
}
</script>
<style scoped>
.reset-password-box {
  text-align: center;
}
.reset-password-title {
  margin: 60px 0;
  font-size: 28px;
}
.reset-password-ruleForm {
  width: 600px;
  margin: 0 auto;
}
.reset-password-ruleForm .phone .el-select {
  margin-right: 2%;
  max-width: 124px;
}
.reset-password-ruleForm /deep/ .el-input__inner{
  height: 56px;
  font-size: 16px;
}
.reset-password-ruleForm /deep/ .el-icon-question {
  margin-right: 10px;
  font-size: 24px;
  vertical-align: bottom;
}
.reset-password-ruleForm /deep/ .el-form-item {
  margin-bottom: 40px;
}
.reset-password-ruleForm /deep/ .el-button--primary{
  margin-top: 20px;
  width: 100%;
  height: 56px;
  font-size: 20px;
  background-color: #008cd6;
  border-color: #008cd6;
}
.reset-password-ruleForm .phone /deep/ .el-form-item__content {
  display: flex;
}
.reset-password-ruleForm /deep/ .el-button--info {
  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
}
.reset-password-ruleForm /deep/ .el-button--success {
  margin-left: 12px;
  background-color: #d2ef7c;
  border-color: #DCDFE6;
  color: #313131;
  font-weight: 400;
  font-size: 14px;
}
</style>