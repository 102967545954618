
import request from "@/utils/request.js";

/**
 * 
 * 验证邮件
 * @returns 
 */
export function checkMail(params) {
  return request({
    method: "post",
    url: "/Mail/check",
    params,
  });
}

/**
 * 
 * 发送验证邮件
 * @returns 
 */
export function sendMail(params) {
  return request({
    method: "post",
    url: "/Mail/send",
    data: params,
  });
}


