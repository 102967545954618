import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    nickname: localStorage.getItem('token') || null,
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data;
      localStorage.setItem('token', data);
    },
    SET_NICKNAME: (state, data) => {
      state.nickname = data;
      localStorage.setItem('nickname', data)
    },
    REMOVE_TOKEN: () => {
      localStorage.removeItem('token');
    },
  },
  getters: {
    token: state => state.token,
    nickname: state => state.nickname,
  },
  actions: {
    setUserRolesInfo({ commit }, data) {
      commit("SET_TOKEN", data.koken);
      commit("SET_NICKNAME", data.nickname);
    },
  },
});
