<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <div class="m-pages-background">
      <h3 class="title">新闻中心<span class="f-block-line"></span></h3>
    </div>
    <!-- content -->
    <div class="m-content main">
      <div class="m-news-information" v-if="newsInfoData">
        <h2 class="news-title">{{ newsInfoData.title }}</h2>
        <p class="news-oper">
          <span class="fl">
            发布时间：{{ newsInfoData.add_time }}
          </span>
          <span class="fr">
            <span>
              <i class="el-icon-view"></i>
              {{ newsInfoData.views }}
            </span>
            <!-- <span>
              <i class="el-icon-thumb"></i>
              2000
            </span> -->
          </span>
        </p>
        <div class="news-content" v-html="newsInfoData.content"></div>
      </div>
    </div>
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from "../components/FooterMod.vue";
import { getNewsInfo } from '@/servers/news.js'

export default {
  name: 'NewsInfo',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    return {
      newsInfoData: {},
    };
  },
  mounted() {
    this.getNewsInfoData()
  },
  methods: {
    getNewsInfoData() {
      // 获取新闻列表
      const id = this.getUrlParams('id')
      getNewsInfo({
        id
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.newsInfoData = res.data
        }

      });
    },
    getUrlParams(paramName) {
      let url = window.location.href;
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
  }

}
</script>
<style scoped>
.m-pages-background {
  height: 120px;
  background: url("../assets/news_bg.jpg") no-repeat center;
  background-size: 100%;
  text-align: center;
}
.m-pages-background .title {
  padding-top: 35px;
  font-size: 28px;
  color: #fff;
}
.m-news-information {
  padding-top: 50px;
  font-size: 14px;
}
.m-news-information .news-title {
  margin-bottom: 20px;
  font-size: 28px;
}
.m-news-information .news-oper {
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
  font-size: 12px;
  color: #999;
}
.m-news-information .news-content {
  padding-bottom: 50px;
  line-height: 28px;
}
.f-block-line {
  display: block;
  margin: 0 auto;
  width: 50px;
  border-bottom: 4px solid #008CD6;
}
</style>