<template>
  <div class="m-footer">
    <div class="main">
      <!-- information -->
      <div class="u-information">
        <i class="u-icon-logo" title="留学e汇"></i>
        <p>广州布莱特教育科技有限公司 版权所有</p>
        <p>粤ICP备2023006976</p>
        <p>联网备案号44010502002521</p>
      </div>
      <!-- morelist -->
      <div class="u-morelist">
        <!-- 帮助中心-->
        <ul class="list">
          <li class="title">帮助中心</li>
          <li>
            <router-link :to="{ path: '/PreviewPage', query: { id: 4 } }">常见问题</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/PreviewPage', query: { id: 5 } }">联系我们</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/PreviewPage', query: { id: 1 } }">服务条款</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/PreviewPage', query: { id: 2 } }">隐私政策</router-link>
          </li>
        </ul>
        <!-- 留学e汇 -->
        <ul class="list">
          <li class="title">留学e汇</li>
          <li>
            <router-link to="/AboutUs">关于我们</router-link>
          </li>
          <li>
            <router-link to="/NewsList">新闻中心</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/PreviewPage', query: { id: 6 } }">合作伙伴</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/PreviewPage', query: { id: 7 } }">商务合作</router-link>
          </li>
        </ul>
        <!-- 微信联系二维码 -->
        <ul class="list">
          <li>
            <i class="u-icon-qrcode" title="扫码在线咨询"></i>
            <p>扫码在线咨询</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterMod",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.m-footer {
  height: 180px;
  padding-top: 40px;
  font-size: 14px;
  background-color: #008cd6;
  color: #fff;
  overflow: hidden;
  a {
    color: #ccdafc;
    text-decoration: none;
  }
  .u-information {
    float: left;
    line-height: 30px;
    color: #ccdafc;
  }
  .u-morelist {
    float: right;
    .title {
      font-size: 16px;
      padding-bottom: 14px;
    }
    .list {
      float: left;
      margin: 0 60px;
      line-height: 24px;
      text-align: center;
    }
  }
  .u-icon-logo {
    display: inline-block;
    width: 168px;
    height: 41px;
    background: url("../assets/logo@2x.png") no-repeat;
    background-size: 100%;
  }
  .u-icon-qrcode {
    display: inline-block;
    width: 96px;
    height: 96px;
    background: url("../assets/qrcode@2x.png") no-repeat;
    background-size: 100%;
  }
}
</style>
>
