<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <div class="m-pages-background">
      <h3 class="title">{{ decodeURIComponent(getUrlParams('pageName')) }}<span class="f-block-line"></span></h3>
    </div>
    <!-- content -->
    <div class="m-content main">
      <div class="m-news-information" v-if="newsInfoData">
        <h2 class="news-title">{{ newsInfoData.title }}</h2>
        <div class="news-content" v-html="newsInfoData.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import { getNewsInfo } from '@/servers/news.js'

export default {
  name: 'InfoPages',
  props: {
    msg: String
  },
  components: {
    HeaderMod
  },
  data() {
    return {
      newsInfoData: {},
    };
  },
  mounted() {
    this.getNewsInfoData()
  },
  methods: {
    getNewsInfoData() {
      // 获取新闻列表
      const id = this.getUrlParams('id')
      getNewsInfo({
        id
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.newsInfoData = res.data
        }
      });
    },
    getUrlParams(paramName) {
      let url = window.location.href;
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
  }

}
</script>
<style scoped>
.m-pages-background {
  height: 200px;
  background: url("../assets/news_bg.jpg") no-repeat center;
  text-align: center;
}
.m-pages-background .title {
  padding-top: 75px;
  font-size: 28px;
  color: #fff;
}
.m-news-information {
  padding-top: 50px;
  font-size: 14px;
}
.m-news-information .news-title {
  margin-bottom: 20px;
  font-size: 28px;
}
.m-news-information .news-oper {
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
  font-size: 18px;
  color: #999;
}
.m-news-information .news-content {
  line-height: 28px;
}
.f-block-line {
  display: block;
  margin: 0 auto;
  width: 50px;
  border-bottom: 4px solid #008CD6;
}
</style>