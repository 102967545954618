import request from "@/utils/request.js";

/**
 * 
 * 获取币种
 * @returns 
 */
export function getCurrencyList(params) {
  return request({
    method: "get",
    url: "/Index/getCurrencyList",
    params,
  });
}

/**
 * 
 * 获取手机区号
 * @returns 
 */
export function getZoneList(params) {
  return request({
    method: "get",
    url: "/Index/getZoneList",
    params,
  });
}

/**
 * 
 * 获取院校列表
 * @returns 
 */
export function getCollegeList(params) {
  return request({
    method: "get",
    url: "/Index/getCollegeList",
    params,
  });
}

/**
 * 
 * 院校账号信息来源
 * @returns 
 */
export function getAccountSources(params) {
  return request({
    method: "get",
    url: "/Index/getAccountSources",
    params,
  });
}

/**
 * 
 * 校验用户身份证号码
 * @returns 
 */
export function checkIdCard(params) {
  return request({
    method: "post",
    url: "/Index/checkIdCard",
    data: params,
  });
}

/**
 * 
 * 获取用户拼音
 * @returns 
 */
export function getPinyin(params) {
  return request({
    method: "post",
    url: "/Index/getPinyin",
    data: params,
  });
}