
import request from "@/utils/request.js";

/**
 * 
 * 获取基本信息
 * @returns 
 */
export function getUserInfo(params) {
  return request({
    method: "get",
    url: "/User/getUserInfo",
    params,
  });
}

/**
 * 
 * 修改基本资料
 * @returns 
 */
export function setUserInfo(params) {
  return request({
    method: "post",
    url: "/User/setUserInfo",
    data: params,
  });
}


