import axios from "axios";
//创建axios实例
const service = axios.create({
  baseURL: "https://api.gzlxeh.com/pc",
  timeout: 5000,
});
//请求拦截
service.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return config;
  },
  (error) => {
    //处理错误请求
    return Promise.reject(error);
  }
);
//响应拦截：后端返回来的结果
service.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (response.status == 200 && res.token) {
      localStorage.setItem('token', res.token)
    }

    if (response.data.code == 20099) {
      // 登录过期时处理
      localStorage.removeItem('token');
      localStorage.removeItem('nickname');
      location.reload();
    }
    
    if (response.config.responseType === 'blob') {
      return res;
    }

    if (typeof res === 'string') {
      res = res ? JSON.parse(res) : res;
    }

    return res;
  },
  (err) => {
    console.log("e", err);
    if (err.response && err.response.data) {
      // const code = err.response.status;
      // const msg = err.response.data.message;
    }
    return Promise.reject(err);
  }
);
export default service;
