<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <div class="m-content main">
      <h5 class="user-conter-title">个人中心</h5>
      <div class="user-conter-information">
        <h6 class="title">个人信息</h6>
        <ul class="content" :class="{'f-edit-status': hasEdit}">
          <li>
            <span class="name">用户名</span>

            <el-input v-if="isEdit.nickname" v-model="userInfoData.nickname"></el-input>
            <span v-else class="el-input">{{ userInfoData.nickname }}</span>

            <el-button v-if="isEdit.nickname" type="primary" plain size="mini" @click="handleSetUserInfo('nickname')" class="fr">确认</el-button>
            <el-button v-else type="text" @click="handleEdit('nickname')" class="fr">
              <i class="el-icon-edit-outline"></i>
            </el-button>

          </li>
          <li>
            <span class="name">就读学校</span>

            <el-select v-if="isEdit.college_id" v-model="userInfoData.college_id" filterable remote :remote-method="handleRemoteMethod" placeholder="请选择院校名称">
              <el-option
                v-for="(item, index) in collegeOptions"
                :key="index"
                :label="item.college_name"
                :value="item.id">
                <span>{{ item.college_name }}</span>
                <span class="u-select-span">{{ item.english_name }}</span>
              </el-option>
            </el-select>

            <span v-else class="el-input">{{ collegeName }}</span>
            
            <el-button v-if="isEdit.college_id" type="primary" plain size="mini" @click="handleSetUserInfo('college_id')" class="fr">确认</el-button>
            <el-button v-else type="text" @click="handleEdit('college_id')" class="fr">
              <i class="el-icon-edit-outline"></i>
            </el-button>

          </li>
          <li>
            <span class="name">入读时间</span>

            <el-date-picker
              v-if="isEdit.entry_time" 
              v-model="userInfoData.entry_time"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择入读时间">
            </el-date-picker>
            <span v-else class="el-input">{{ userInfoData.entry_time }}</span>
            
            <el-button v-if="isEdit.entry_time" type="primary" plain size="mini" @click="handleSetUserInfo('entry_time')" class="fr">确认</el-button>
            <el-button v-else type="text" @click="handleEdit('entry_time')" class="fr">
              <i class="el-icon-edit-outline"></i>
            </el-button>

          </li>
          <li class="u-phone-number">
            <span class="name">手机号码</span>

            <span v-if="isEdit.phone_number">
              <el-select v-model="userInfoData.zone_code" placeholder="+86">
                <el-option
                  v-for="item in codeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input v-model="userInfoData.phone_number"></el-input>

              <el-input v-if="hasSmsCode" v-model="userInfoData.verify_code" placeholder="请输入短信验证码"></el-input>
              <el-button v-if="hasSmsCode" type="info" size="medium" @click="sendSmsCode" disabled>{{smsCodeCount}}秒后重试</el-button>
              <el-button v-else type="success" size="medium" @click="sendSmsCode" :disabled="smsCodeCount > 0" class="u-code-button">短信验证码</el-button>
              
            </span>

            <span v-else class="el-input">{{ userInfoData.phone_number }}</span>

            <el-button v-if="isEdit.phone_number" type="primary" plain size="mini" @click="handleSetUserInfo('phone_number')" class="fr">确认</el-button>
            <el-button v-else type="text" @click="handleEdit('phone_number')" class="fr">
              <i class="el-icon-edit-outline"></i>
            </el-button>

          </li>
          <li>
            <span class="name">电子邮箱</span>

            <el-input v-if="isEdit.email" v-model="userInfoData.email"></el-input>
            <span v-else class="el-input">{{ userInfoData.email }}</span>
            
            <el-button v-if="isEdit.email" type="primary" plain size="mini" @click="handleSendMail('email')" class="fr" key="sendCode" :disabled="hasSendEmail">验证</el-button>
            <el-button v-else type="text" @click="handleEdit('email')" class="fr">
              <i class="el-icon-edit-outline"></i>
            </el-button>

          </li>
        </ul>
      </div>
      <div class="user-conter-information">
        <h6 class="title">订单进度</h6>
        <span class="oper">
          <router-link to="/HistoryList" class="f-history-text">历史订单</router-link> | 
          <span @click="handleWChatDialog">查不到订单?</span>
        </span>
        <div class="content">
          <p class="steps-header">
            <span class="steps-title">{{ orderInfoData.remit_amount_str }}</span>
            <span class="steps-time">{{ orderInfoData.add_time }}</span>
          </p>
          <el-steps v-if="orderInfoData.order_status == 1
          || orderInfoData.order_status == 2
          || orderInfoData.order_status == 3 
          || orderInfoData.order_status == 4" :active="orderInfoData.order_status + 1" align-center class="u-steps-tips">
            <el-step title="下单完成"></el-step>
            <el-step title="等待付款"></el-step>
            <el-step title="正在审核"></el-step>
            <el-step title="换汇中"></el-step>
            <el-step title="学费汇出"></el-step>
          </el-steps>
          <div class="operate">
            <el-button @click="handleOperate('orderInfo')" :class="{ 'f-tab-selected': orderInfoTab }">订单详情</el-button>
            <el-button @click="handleOperate('payInfo')" :class="{ 'f-tab-selected': payInfoTab }">付款信息</el-button>
            <el-button v-if="orderInfoData.order_status === 1" @click="handleCancelOrder">取消订单</el-button>
          </div>
          <!-- 订单详情 -->
          <div v-show="orderInfoTab" class="operate-more">
            <h6 class="title">订单信息</h6>
            <ul>
              <li>
                <span class="name">收款单位</span>
                <span>{{ orderInfoData.college_name }}</span>
              </li>
              <li>
                <span class="name">订单编号</span>
                <span>{{ orderInfoData.order_number }}</span>
              </li>
              <li>
                <span class="name">订单金额</span>
                <span>{{ orderInfoData.total_amount }}</span>
              </li>
              <li>
                <span class="name">下单时间</span>
                <span>{{ orderInfoData.add_time }}</span>
              </li>
            </ul>
          </div>
          <!-- 付款详情 -->
          <div v-show="payInfoTab" class="operate-more">
            <h6 class="title">付款信息</h6>
            <ul>
              <li>
                <span class="name">外币金额</span>
                <span>{{ orderInfoData.remit_amount_str }}</span>
              </li>
              <li>
                <span class="name">足额到账服务</span>
                <span>{{ orderInfoData.fully_rec_fee }}</span>
              </li>
              <li>
                <span class="name">平台服务费</span>
                <span>{{ orderInfoData.cross_fee }}</span>
              </li>
              <li>
                <span class="name">转账手续费</span>
                <span>{{ orderInfoData.transfer_fee }}</span>
              </li>
              
              <li class="f-text-blod">
                <span class="name">合计金额</span>
                <span>{{ orderInfoData.total_amount_str }}</span>
              </li>

              <li>
                <span class="name">付款方式</span>
                <span>转账支付</span>
              </li>
              <li>
                <span class="name">收款户名</span>
                <span>{{ orderInfoData.pay_account_name }}</span>
              </li>
              <li>
                <span class="name">收款账号</span>
                <span>{{ orderInfoData.pay_account_num }}</span>
              </li>
              <li>
                <span class="name">收款银行</span>
                <span>{{ orderInfoData.pay_bank_name }}</span>
              </li>
              <li>
                <span class="name">开户地</span>
                <span>{{ orderInfoData.pay_bank_place }}</span>
              </li>
              <li>
                <span class="name">开户网点</span>
                <span>{{ orderInfoData.pay_bank_branch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <FooterMod />

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="700px"
      center
      class="u-information-pop"
    > 
    <div v-if="deadlineTimeText">
      <div class="tips-pop-content">
        <p>您好！您的订单尚未付款</p>
        <p class="f-text-tips">剩余支付时间还有{{ deadlineTimeText }}</p>
        <p>确认付款请点击"去付款",确认取消订单请点击"确认取消"</p>
      </div>
      <div class="u-info">
        <el-button @click="handleGoPay">去付款</el-button>
        <el-button type="primary" @click="handleUpdateOrderStatus">确认取消</el-button>
      </div>
    </div>
    <div v-else class="tips-pop-content" style="padding-bottom: 40px">
      <p class="f-text-tips">您好，您的订单己超时，请重新下单或到历史订单中再次缴费。</p>
    </div>

    </el-dialog>

    <el-dialog
      :visible.sync="weChatDialogVisible"
      :close-on-click-modal="false"
      width="640px"
      center
      class="u-wechat-pop"
    >
      <div class="tips-pop-content">
        <p class="title">
          <span class="tips">查不到订单？</span>
          请添加客户经理微信
        </p>
        <img class="pic" src="../assets/icon_big_wechat.png" />
        <p class="content">
          <img src="../assets/icon_wechat.png" /><span class="sub-title">微信号</span> | <span class="number">BLT202306</span>
        </p>
        <p class="content">
          <img src="../assets/icon_phone.png" /><span class="sub-title">联系电话</span> | <span class="number">400-998-7723</span>
        </p>

      </div>
    </el-dialog>

  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from "../components/FooterMod.vue"
import { getUserInfo, setUserInfo } from '@/servers/user.js'
import { getCollegeList, getZoneList } from '@/servers/index.js'
import { updateOrderStatus } from '@/servers/order.js'
import { getVerifyCode } from '@/servers/login.js'
import { sendMail } from '@/servers/mail.js'

export default {
  name: 'UserCenter',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    return {
      orderInfoTab: false,
      payInfoTab: false,
      activeName: 'first',
      dialogVisible: false,
      weChatDialogVisible: false,
      hasEdit: false,
      hasSmsCode: false,
      smsCodeCount: 0,
      collegeName: '',
      userInfoData: {},
      orderInfoData: {},
      hasSendEmail: false,
      isEdit: {
        nickname: false,
        college: false,
        entry_time: false,
        phone_number: false,
        email: false,
      },
      deadlineTimeText: '',
      deadlineTime: 0,
      codeOptions: [],
      collegeOptions: [],
      fileList: [{url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    };
  },
  watch: {
    'isEdit.phone_number': {
      handler(newVal) {
        if (newVal) {
          if (this.codeOptions.length === 0) {
            this.getZoneListData();
          }
        }
      },
      deep: true,
    },
  },
  created() {
    document.addEventListener('click', e => {
      this.clickEditSummit(e);
    })
  },
  mounted() {
    this.getUserInfoData();
  },
  methods: {
    getUserInfoData() {
      // 获取基本信息
      getUserInfo().then((res) => {
        if (Number(res.code) === 20000) {
          const { user_info, order_info } = res.data

          this.userInfoData = user_info
          this.orderInfoData = order_info
          this.getCollegeListData()

          this.setTimeOut()
        }

        if (Number(res.code) === 20099) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          // 跳转至登录页面
          this.$router.push('/LoginRegister');
   
        }
      });
    },
    getCollegeListData() {
      // 获取院校列表
      getCollegeList().then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          if (list.length) {

            this.collegeOptions = list

            // "就读学校"名字渲染
            this.resetCollegeName()

          }
        }
      });
    },
    resetCollegeName() {
      // 读取"学校"名字
      this.collegeOptions.forEach( v => {
        if (v.id === this.userInfoData.college_id) {
          this.collegeName = v.college_name
        }
      })
    },
    handleRemoteMethod(key) {
      // 远程搜索
      getCollegeList({
        keyword: key
      }).then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          if (list.length) {

            this.collegeOptions = list

            // "就读学校"名字渲染
            this.resetCollegeName()

          }
        }
      });
    },
    handleEdit(params) {
      // 点击编辑按钮
      this.isEdit[params] = true
      this.hasEdit = true
    },
    handleSetUserInfo(params) {
      // 修改基本资料
      setUserInfo({
        [params]: this.userInfoData[params]
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.isEdit[params] = false
          this.hasEdit = false
          
          if (params === 'college_id') {
            // "就读学校"名字渲染
            this.resetCollegeName()
          }
          
          if (params === 'nickname') {
            // 修改明白的话存储
            localStorage.setItem('nickname', this.userInfoData.nickname)
            location.reload()
          }

        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
  
      });
    },
    handleClose() {
      // 关闭弹窗
      this.dialogVisible = false;
    },
    handleOperate(tabName) {
      // 切换"订单详情"or"付款信息"
      this.orderInfoTab = tabName === 'orderInfo' ? true : false
      this.payInfoTab = tabName === 'orderInfo' ? false : true
    },
    handleCancelOrder() {
      // 取消订单弹窗
      this.dialogVisible = true
    },
    handleUpdateOrderStatus() {
      // 确认取消订单
      updateOrderStatus({
        order_number: this.orderInfoData.order_number,
        type: 2 // 1 已支付 2取消
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          // 跳转到"历史订单"页面，并选中"已取消"Tab
          this.$router.push('/HistoryList?status=3');
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
  
      });
    },
    handleWChatDialog() {
      // 查看不到订单的指引弹窗
      this.weChatDialogVisible = true;
    },
    getZoneListData() {
      // 获取手机区号
      getZoneList().then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          const zoneList = []
          list.forEach( v => {
            zoneList.push({
              value: v.zone,
              label: v.name
            })
          })
          
          this.codeOptions = zoneList;

          // 默认先帮用户选择第一个
          this.userInfoData.zone_code = zoneList[0].value;
        }
      });
    },
    sendSmsCode() {
      let clickSmsCodeTime = new Date().getTime() + 60000;
      sessionStorage.setItem('SmsCodeEndTime', JSON.stringify(clickSmsCodeTime));
      
      // 发送验证码
      getVerifyCode({
        zone_code: this.userInfoData.zone_code,
        phone_number: this.userInfoData.phone_number,
        type: 3 // 短信类型 1注册 2忘记密码 3更换手机 4其他
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          this.hasSmsCode = true;
          this.smsCodeTimeDown(clickSmsCodeTime);

        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    smsCodeTimeDown(time) {
      if (!this.timer) {
        this.smsCodeCount = Math.ceil((JSON.parse(time) - new Date().getTime()) /1000);
        this.timer = setInterval( () => {
          if (this.smsCodeCount > 0) {
            this.smsCodeCount --;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            sessionStorage.removeItem('SmsCodeEndTime');
            this.hasSmsCode = false;
          }
        }, 1000)
      }
    },
    handleSendMail() {
      this.hasSendEmail = false;
      if (this.userInfoData.email == '') {
        this.$message({
          message: '请输入邮箱地址！',
          type: "error",
        });
        return false;
      }
      // 发送验证邮件
      sendMail({
        email: this.userInfoData.email,
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          
          this.hasSendEmail = true;

        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    countDown() {
      this.deadlineTime = this.orderInfoData.deadline_time
      const endTime = this.deadlineTime * 1000;
      const nowTime = Date.parse(new Date().toString());
      let maxTime = (endTime - nowTime) / 1000 

      if (maxTime >= 0) {

        let hour = Math.floor(maxTime / 60 / 60 % 24);

        if (hour < 9) {
          hour = hour ? '0' + hour : '0'
        }

        let min = Math.floor(maxTime / 60 % 60);
        if (min < 9) {
          min = min ? '0' + min : '0'
        }

        let sec = Math.floor(maxTime % 60);
        if (sec < 9) {
          sec = sec ? '0' + sec : '0'
        }

        this.deadlineTimeText = ` ${hour}时 ${min}分 ${sec}秒 `
        maxTime --
      } else {
        clearInterval(this.deadlineTimeText);
      }

    },
    setTimeOut() {
      setInterval( () => {
        this.deadlineTime -= 1
        this.countDown();
      }, 1000)
    },
    clickEditSummit(e) {
      if (e.target.nodeName == 'DIV' || e.target.nodeName == 'UL' || e.target.nodeName == 'LI') {
        this.isEdit = {
          nickname: false,
          college: false,
          entry_time: false,
          phone_number: false,
          email: false,
        };
        this.hasEdit = false;
      }
    },
    handleGoPay() {
      const collegeId = this.userInfoData.college_id;
      const orderNumber = this.orderInfoData.order_number;
      this.$router.push(`/PaymentForm?collegeId=${collegeId}&orderNumber=${orderNumber}&orderType=3`); // order_type: 1新下单 2再次缴费 3编辑订单信息
    },
  }

}
</script>
<style scoped>
.user-conter-title {
  margin-top: 40px;
  text-align: center;
  font-size: 28px;
}
.user-conter-information {
  position: relative;
  font-size: 16px;
}
.user-conter-information .title {
  font-size: 20px;
}
.user-conter-information .content {
  margin: 10px 0 80px;
  padding: 26px 34px;
  line-height: 42px;
  border-radius: 8px;
  border: 2px solid #BFBFBF;
}
.user-conter-information .content li {
  overflow: hidden;
}
.user-conter-information .name {
  display: inline-block;
  width: 130px;
  color: #008CD6;
}
.user-conter-information .oper {
  position: absolute;
  right: 0;
  top: 0;
}
.user-conter-information .oper span {
  color: #008CD6;
  cursor: pointer;
}
.user-conter-information .oper a {
  text-decoration: none;
  color: #008CD6;
}
.user-conter-information .oper a.f-history-text {
  color: #7D7D7D;
}
.user-conter-information .steps-header {
  overflow: hidden;
}
.user-conter-information .steps-title {
  float: left;
  color: #008CD6;
  font-size: 26px;
  font-weight: 700;
}
.user-conter-information .steps-time {
  float: right;
  color: #A0A0A0;
}
.user-conter-information .el-input,
.user-conter-information .el-select {
  margin-right: 10px;
  width: 30%;
}
.u-steps-tips {
  margin: 30px 0;
  line-height: 24px;
}
/* .u-steps-tips /deep/ .el-step.is-center .el-step__line {
  left: 0;
  right: 0;
  width: 200%;
} */
.f-text-blod {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
}
.u-information-pop /deep/ .el-dialog {
  border-radius: 10px;
}
.u-information-pop /deep/ .el-dialog__header {
  padding-top: 40px;
}
.u-information-pop /deep/ .el-dialog__body {
   padding: 25px 48px 30px;
}
.u-information-pop /deep/ .el-dialog__title {
  font-size: 24px;
  font-weight: 700;
}
.u-information-pop .u-info {
  text-align: center;
}
.operate .el-button,
.u-information-pop .u-info .el-button {
  margin: 22px 10px 22px;
  width: 280px;
  height: 56px;
  font-size: 16px;
}
.operate .el-button--default,
.u-information-pop .u-info .el-button--default {
  border: 2px solid #008CD6;
  color: #008CD6;
}
.operate .el-button--primary,
.u-information-pop .u-info .el-button--primary {
  background-color: #008CD6;
  color: #fff;
}
.operate-more {
  padding-left: 20px;
}
.tips-pop-content {
  line-height: 40px;
  font-size: 16px;
  text-align: center;
}
.tips-pop-content .pic {
  width: 280px;
  height: 280px;
}
.f-text-tips {
  color: red;
}
.operate .f-tab-selected {
  border: 2px solid #008CD6;
  background-color: #008CD6;
  color: #fff;
}
.el-button--primary.is-plain {
  margin-top: 11px;
  background-color: #fff;
  border: 1px solid #008CD6;
  border-radius: 8px;
  color: #008CD6;
  font-size: 16px;
}
.el-icon-edit-outline {
  font-size: 24px;
  vertical-align: middle;
} 
.user-conter-information /deep/ .el-input {
  font-size: 16px;
}
.user-conter-information /deep/ .el-input__inner {
  margin: 2px;
  height: 48px;
  line-height: 48px;
}

.u-wechat-pop {
  text-align: center;
}
.u-wechat-pop .title{
  margin: 20px 0;
  font-size: 24px;
}
.u-wechat-pop .tips {
  color: #008CD6;
}
.u-wechat-pop .sub-title {
  display: inline-block;
  width: 90px;
  color: #313131;
}
.u-wechat-pop .number {
  margin-left: 10px;
}
.u-wechat-pop .content {
  margin: 10px auto 0;
  width: 325px;
  text-align: left;
  color: #A0A0A0;
}
.u-wechat-pop .content img {
  margin-right: 20px;
  vertical-align: middle;
}
.u-wechat-pop .tips-pop-content {
  padding-bottom: 30px;
}
.u-select-span {
  margin-left: 10px;
  font-size: 13px;
  color: #8492a6;
}
.f-edit-status .el-button--text {
  display: none;
}
.user-conter-information .u-phone-number /deep/ .el-select {
  width: 90px;
}
.u-phone-number /deep/ .el-button--success,
.u-phone-number /deep/ .el-button--info{
  margin-right: 8px;
  padding: 14px 10px;
}
.u-code-button {
  background-color: #008CD6;
  border-color: #008CD6;
  color: #fff;
}
.fr {
  float: right;
}

/deep/ .el-step__head.is-finish,
/deep/ .el-step__title.is-finish {
  color: #008CD6;
}

/deep/ .el-step__head.is-process,
/deep/ .el-step__title.is-process {
  color: #C0C4CC;
  font-weight: 400;
}

/deep/ .is-process .el-step__icon.is-text {
  border: 2px solid #C0C4CC;
}
</style>