<template>
  <div class="g-container f-header-fixed">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
      <!-- 进度条 -->
      <el-steps :active="0" align-center class="u-steps-tips">
        <div class="main" style=" display: flex; margin: 50px auto 0; width: 1400px;">
          <el-step title="填写信息" icon="el-icon-success"></el-step>
          <el-step title="上传资料" icon="el-icon-remove"></el-step>
          <el-step title="缴费支付" icon="el-icon-remove"></el-step>
        </div>
      </el-steps>
      <!-- 填写信息 -->
      <div class="m-content main">
        <p class="collegeinfo-title">
          <span class="tips">申请院校</span>
          <span class="subtitle">{{ collegeInfo.college_name ? collegeInfo.college_name : getUrlParams('collegeName') }}</span>
          {{ collegeInfo.english_name ? collegeInfo.english_name : getUrlParams('englishName') }}</p>
        <div class="content">
          <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="collegeinfo-ruleForm">
            <h5 class="title">1.付款信息</h5>
            <el-form-item prop="account_id" label="收款账户" class="u-account-input">
              <el-select v-model="ruleForm.account_id" @change="handleSelectAccountId
              " :placeholder="`该院校有${codeOptions.length}个收款账户，请仔细核对并选择账户汇款！`">
                <el-option
                  v-for="(item, index) in codeOptions"
                  :key="index"
                  :label="item.account_name"
                  :value="item.id"
                  class="collection-list-box">
                  <p class="list-header">
                    <span class="context">
                      <img :src="item.img_url" class="u-icon-swift" />
                      <span class="name">{{ item.transfer_name }}</span>
                      <span class="tips">{{ item.describe }}</span>
                    </span>
                    <span v-if="item.account_tag" class="tag">{{ item.account_tag }}</span>
                  </p>
                  <p class="list-footer">
                    <span>
                      <span class="subtitle">收款账户名</span>
                      <span class="cardnum">{{ item.account_name }}</span>
                    </span>
                    <span v-if="item.transfer_type == 1 || item.transfer_type == 5">
                      <span class="subtitle">收款账户号</span>
                      <span class="cardnum">{{ item.account_num }}</span>
                    </span>
                    <span v-if="item.transfer_type == 2">
                      <span class="subtitle">Bill Code</span>
                      <span class="cardnum">{{ item.biller_code }}</span>
                    </span>
                  </p>

                </el-option>
              </el-select>
            </el-form-item>

            <!-- 选中"付款信息"把对应的信息显示出来 start -->
            <div v-if="chooseAccountData.length > 0" class="collection-list-box f-choose-account">
              <p class="list-header">
                <span class="context">
                  <img :src="chooseAccountData[0].img_url" class="u-icon-swift" />
                  <span class="name">{{ chooseAccountData[0].transfer_name }}</span>
                  <span class="tips">{{ chooseAccountData[0].describe }}</span>
                </span>
                <span v-if="chooseAccountData[0].account_tag" class="tag">{{ chooseAccountData[0].account_tag }}</span>
              </p>
              <p class="list-footer">
                <span>
                  <span class="subtitle">收款账户名</span>
                  <span class="cardnum">{{ chooseAccountData[0].account_name }}</span>
                </span>
                <span v-if="chooseAccountData[0].transfer_type == 1">
                  <span class="subtitle">收款账户号</span>
                  <span class="cardnum">{{ chooseAccountData[0].account_num }}</span>
                </span>
                <span v-if="chooseAccountData[0].transfer_type == 2">
                  <span class="subtitle">Bill Code</span>
                  <span class="cardnum">{{ chooseAccountData[0].biller_code }}</span>
                </span>
              </p>
            </div>
            <!-- 选中"付款信息"把对应的信息显示出来 end -->

            <!-- 选择BPay时出现"Reference Number" -->
            <el-form-item v-if="ruleForm.account_id && transferType() == 2" prop="reference_number" label="Reference Number" class="special-item">
              <el-input v-model="ruleForm.reference_number" placeholder="请按照缴费通知单填写">
                <el-tooltip slot="suffix" content="请参考缴费通知单，注意内容前后不要留空" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <!-- 选择Bill Payment时出现"Account Number" -->
            <el-form-item v-if="ruleForm.account_id && transferType() == 3" prop="account_number" label="Account Number" class="special-item">
              <el-input v-model="ruleForm.account_number" placeholder="请按照缴费通知单填写">
                <el-tooltip slot="suffix" content="请参考缴费通知单，注意内容前后不要留空" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <div class="f-display-flex">
              <el-form-item prop="remitter" label="汇款方">
                <el-input v-model="apiData.remitter" placeholder="中国" :disabled="true"></el-input>
              </el-form-item>
              <span class="u-icon-arrow"></span>
              <el-form-item prop="payee" label="收款方">
                <el-input v-model="apiData.payee" placeholder="澳大利亚" :disabled="true"></el-input>
              </el-form-item>
            </div>

            <el-form-item prop="remit_amount" label="汇款金额">
              <el-input v-model="ruleForm.remit_amount" :placeholder="`0.00${ currencyCode ? '(' + currencyCode + ')' : '' }`">
                <el-tooltip slot="suffix" content="应监管部门审核要求，填写金额请与“账单金额”保持一致或低于“账单金额”。" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
              
            </el-form-item>
            <el-form-item v-if="apiData.full_fee != ''" prop="full_fee_service" label="足额到账服务">
              <el-radio-group v-model="ruleForm.full_fee_service" class="u-college-radio">
                <el-radio :label="1" border>{{ apiData.full_fee }}</el-radio>
                <el-radio :label="2" border>不使用服务</el-radio>
              </el-radio-group>
              <el-tooltip content="足额到账服务是资金在跨境转款时由境外中间银行收取的中转手续费，为了资金可以不受境外中间银行的中转手续费影响足额到达收款方银行账户 (若您不使用足额服务，比如您转款 $10,000，实际到达收款方账户的金额可能是 $9,975左右)。所有跨境汇款业务均会遇到此项费用，也欢迎您到任何一家可以做外汇业务的银行核实该项费用的真实性。" placement="top" effect="dark">
                <i class="el-icon-question u-icon-question"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="apiData.full_fee != ''" class="u-question-tips">
              <i class="el-icon-warning-outline"></i>
              <p class="qa-title">
                该费用不包含入账费，到账后是否会扣除入账费请仔细查看账单说明
              </p>
              
              <!-- 选择"不使用服务时"使用如下 -->
              <span v-if="ruleForm.full_fee_service == 2" class="qa-text">交学费请务必选择使用足额到账服务。如果学费未能足额到账，学校将视为未缴清学费，逾期会影响学生选课，还会罚款。银行的国际电汇同样也会扣除该费用。</span>
              <span v-else class="qa-text">选择该服务，系统会自动追加结算(无需自己手动添加这部分金额到账金额中)。</span>

            </el-form-item>

            <el-form-item prop="pay_use_input" label="汇款用途">
              <el-select v-model="ruleForm.pay_use_input" multiple placeholder="可多选">
                <el-option
                  v-for="item in payUseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="isShowOtherInput(ruleForm.pay_use_input)" prop="other" label="其他 Other">
              <el-input v-model="ruleForm.other" placeholder="请输入其他 Other"></el-input>
            </el-form-item>
            
            <!-- 学生信息 -->
            <h5 class="title">2.学生信息</h5>
            <el-form-item prop="name_ch" label="学生姓名" class="u-flex-layout">
              <el-input v-model="ruleForm.name_ch" placeholder="中文姓名（与身份证一致）"></el-input>
              <el-input v-model="ruleForm.name_last" placeholder="姓拼音" class="sub-input"></el-input>
              <el-input v-model="ruleForm.name_first" placeholder="名字拼音" class="sub-input"></el-input>
            </el-form-item>
            <el-form-item prop="id_card" label="身份证号">
              <el-input v-model="ruleForm.id_card" placeholder="中国居民身份证号（学生本人）">
                <el-tooltip slot="suffix" content="身份证号码用于到监管部门核实学生身份" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <el-form-item prop="mobile" label="手机号码">
              <el-input v-model="ruleForm.mobile" placeholder="+86 | 用于接收通知的中国手机号">
                <el-tooltip slot="suffix"  content="请填写您用于支付的银行卡在该银行预留的手机号码" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <el-form-item prop="email" label="电子邮箱">
              <el-input v-model="ruleForm.email" placeholder='用于接收"汇款回执"邮箱'>
                <el-tooltip slot="suffix"  content="用于发送订单追踪状态" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <!-- 学籍信息 -->
            <h5 class="title">3.学籍信息</h5>
            <el-form-item prop="student_id" label="学生证号">
              <el-input v-model="ruleForm.student_id" placeholder='输入studentId，没有填"无"'></el-input>
            </el-form-item>
            <el-form-item prop="edu_year" label="学制">
              <el-select v-model="ruleForm.edu_year" placeholder="请选择！">
                <el-option
                  v-for="(item, index) in educationalOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="remark" label="附言" class="f-text-tips">
              <el-input v-model="ruleForm.remark" placeholder="附言很重要，请按学校要求填写">
                <el-tooltip slot="suffix"  content="在缴费通知单上可查看，一般为学生名字+学号，请以实际附言内容为准" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <!-- 付款人信息 -->
            <h5 class="title">4.付款人信息</h5>
            <el-form-item prop="payer_relation" label="与学生关系">
              <el-select v-model="ruleForm.payer_relation" placeholder="付款人与学生的关系">
                <el-option
                  v-for="item in payerOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item :prop="isChooseThemselves ? '' : 'payer_name_ch'" label="中文姓名">
              <el-tooltip v-if="isChooseThemselves" content="请在本页【学生信息】处修改个人信息" placement="top-start">
                <el-input v-model="ruleForm.payer_name_ch" placeholder="请填写付款人中文名" :disabled="isChooseThemselves"></el-input>
              </el-tooltip>
              <el-input v-else v-model="ruleForm.payer_name_ch" placeholder="请填写付款人中文名"></el-input>
            </el-form-item>
            <el-form-item :prop="isChooseThemselves ? '' : 'payer_id_card'" label="身份证号">
              <el-tooltip v-if="isChooseThemselves" content="请在本页【学生信息】处修改个人信息" placement="top-start">
                <el-input v-model="ruleForm.payer_id_card" placeholder="请填写付款人身份证号码" :disabled="isChooseThemselves"></el-input>
              </el-tooltip>
              <el-input v-else v-model="ruleForm.payer_id_card" placeholder="请填写付款人身份证号码"></el-input>
            </el-form-item>
            <el-form-item :prop="isChooseThemselves ? '' : 'payer_mobile'" label="手机号码">
              <el-tooltip v-if="isChooseThemselves" content="请在本页【学生信息】处修改个人信息" placement="top-start">
                <el-input v-model="ruleForm.payer_mobile" placeholder="+86 | 请填写付款人手机号码" :disabled="isChooseThemselves"></el-input>
              </el-tooltip>
              <el-input v-else v-model="ruleForm.payer_mobile" placeholder="+86 | 请填写付款人手机号码"></el-input>
            </el-form-item>
            <el-form-item :prop="isChooseThemselves ? '' : 'payer_email'" label="电子邮件">
              <el-tooltip v-if="isChooseThemselves" content="请在本页【学生信息】处修改个人信息" placement="top-start">
                <el-input v-model="ruleForm.payer_email" placeholder="请输入付款人电子邮箱" :disabled="isChooseThemselves"></el-input>
              </el-tooltip>
              <el-input v-else v-model="ruleForm.payer_email" placeholder="请输入付款人电子邮箱"></el-input>
            </el-form-item>
            <el-form-item prop="referrer_mobile" label="推荐人手机">
              <el-input v-model="ruleForm.referrer_mobile" placeholder="选填，请输入推荐人手机号码">
                <el-tooltip slot="suffix"  content="请填写推荐您使用留学e汇缴费平台的老师或客户经理手机号" placement="top" effect="dark">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-input>
            </el-form-item>
            <el-form-item class="u-infor">
              <p class="submit-text-tips">
                提交此订单即表示您同意
                <router-link target="_blank" :to="{ path: '/PreviewPage', query: { id: 3 } }" >
                  《留学e汇 缴费咨询服务协议》
                </router-link>
              </p>
              <el-button type="primary" @click="submitForm('ruleForm')" class="u-icon-submit">提交</el-button>
            </el-form-item>

          </el-form>

        </div>
      </div>
      
      <el-dialog
        title="确认信息"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :show-close="false"
        width="900px"
        center
        class="u-information-pop"
      >
        <div class="tips-pop-content">
          <h5 class="title">
            1、付款信息
          </h5>
          <div class="box">
            <p>
              <span class="box-name">学校名称</span>
              <span class="box-content">{{collegeInfo.college_name ? collegeInfo.college_name : getUrlParams('collegeName') }}</span>
            </p>
            <p>
              <span class="box-name">汇款金额</span>
              <span class="box-content">{{ informationList.remit_amount}}{{ '(' + currencyCode + ')' }}</span>
            </p>
            <p>
              <span class="box-name">足额到账服务</span>
              <span class="box-content">{{ informationList.full_fee_service == 1 ? '足额到账费' : '不选择' }}</span>
            </p>
            <p>
              <span class="box-name">付款用途</span>
              <span class="box-content" v-if="informationList.pay_use_input">{{ informationList.pay_use_input.join(',') }}</span>
            </p>
          </div>
        </div>

        <div class="tips-pop-content">
          <h5 class="title">
            2、学生信息
          </h5>
          <div class="box">
            <p>
              <span class="box-name">中文姓名</span>
              <span class="box-content">{{ informationList.name_ch }}</span>
            </p>
            <p>
              <span class="box-name">身份证号</span>
              <span class="box-content">{{ informationList.id_card }}</span>
            </p>
            <p>
              <span class="box-name">手机号码</span>
              <span class="box-content">{{ informationList.mobile }}</span>
            </p>
            <p>
              <span class="box-name">电子邮箱</span>
              <span class="box-content">{{ informationList.email }}</span>
            </p>
          </div>
        </div>
        
        <div class="tips-pop-content">
          <h5 class="title">
            3、学籍信息
          </h5>
          <div class="box">
            <p>
              <span class="box-name">学生证号</span>
              <span class="box-content">{{ informationList.student_id }}</span>
            </p>
            <p>
              <span class="box-name">学制</span>
              <span class="box-content">{{ informationList.edu_year }}</span>
            </p>
            <p>
              <span class="box-name">附言</span>
              <span class="box-content">{{ informationList.remark }}</span>
            </p>
          </div>
        </div>

        <div class="tips-pop-content">
          <h5 class="title">
            4、付款人信息
          </h5>
          <div class="box">
            <p>
              <span class="box-name">与学生关系</span>
              <span class="box-content">{{ informationList.payer_relation }}</span>
            </p>
            <p>
              <span class="box-name">中文姓名</span>
              <span class="box-content">{{ informationList.payer_name_ch }}</span>
            </p>
            <p>
              <span class="box-name">身份证号</span>
              <span class="box-content">{{ informationList.payer_id_card }}</span>
            </p>
            <p>
              <span class="box-name">手机号码</span>
              <span class="box-content">{{ informationList.payer_mobile }}</span>
            </p>
            <p>
              <span class="box-name">电子邮箱</span>
              <span class="box-content">{{ informationList.payer_email }}</span>
            </p>
            <p>
              <span class="box-name">推荐人手机</span>
              <span class="box-content">{{ informationList.referrer_mobile }}</span>
            </p>
          </div>
        </div>

        <div class="u-info">
          <p>信息确认提交后不可修改，<span class="tips">请仔细核对！</span></p>
          <el-button @click="handleClose">返回修改</el-button>
          <el-button type="primary" @click="handleAddOrder()">确认无误，提交</el-button>
        </div>

      </el-dialog>
      
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import { saveOrder, beforeOrder } from '@/servers/order.js'
import { checkIdCard, getPinyin } from '@/servers/index.js'

export default {
  name: 'CollegeInfoForm',
  props: {
    msg: String
  },
  components: {
    HeaderMod
  },
  data() {
    // 校验email格式
    let checkEmail = (rule, value, callback) => {
      let reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if (!value) {
        callback(new Error("请输入电子邮箱"));
      } else if (!reg.test(value)) {
        callback(new Error("邮箱格式不正确，请检查!"));
      } else {
        callback();
      }
    };
    // 校验手机格式
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[34578][0-9]\d{8}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!reg.test(value)) {
        callback(new Error("手机号码格式不正确，请检查!"));
      } else {
        callback();
      }
    };
    // 校验身份证号码
    let checkIdCard = (rule, value, callback) => {
      let reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;
      if (!value) {
        callback(new Error("请输入身份证号码"));
      } else if (value.length != 18 || !reg.test(value)) {
        callback(new Error("身份证号码格式不正确，请检查!"));
      } else {
        this.checkIdCardData();
        callback();
      }
    };
    // 校验姓拼音
    let checkSurName = (rule, value, callback) => {
      let reg = /[^a-zA-Z ]/;
      if (!value) {
        callback(new Error("请输入姓拼音"));
      } else if (!reg.test(value)) {
        callback(new Error("格式不正确，请检查!"));
      } else {
        callback();
      }
    };
    // 校验中文姓名
    let checkStudentName = (rule, value, callback) => {
      let reg = /^[\u4e00-\u9fa5]{2,4}$/;
      if (!value) {
        callback(new Error("请输入中文姓名"));
      } else if (!reg.test(value)) {
        callback(new Error("格式不正确，请检查!"));
      } else {
        this.getPinyinData();
        callback();
      }
    };

    // 校验中文姓名
    let checkStudent2Name = (rule, value, callback) => {
      let reg = /^[\u4e00-\u9fa5]{2,4}$/;
      if (!value) {
        callback(new Error("请输入中文姓名"));
      } else if (!reg.test(value)) {
        callback(new Error("格式不正确，请检查!"));
      } else {
        callback();
      }
    };
    
    return {
      dialogVisible: false,
      isChooseThemselves: false, // 学生本人
      currencyCode: '',
      informationList: [],
      chooseAccountData: {},
      educationalOptions: [
        {
          value: '一年以下',
          label: '一年以下',
        },
        {
          value: '一年或以上',
          label: '一年或以上',
        },
      ],
      isCallbackEdit: false,
      codeOptions: [],
      payUseOptions: [],
      payerOptions: [],
      apiData: {},
      collegeInfo: {},
      ruleForm: {
        referrer_mobile: ''
      },
      rules: {
        account_id: [{ required: true, message: '请选择账户', trigger: 'blur' }],
        collection: [{ required: true, message: '请输入收款账户', trigger: 'blur' }],
        // remitter: [{ required: true, message: '请输入汇款方', trigger: 'blur' }],
        // payee: [{ required: true, message: '请输入收款方', trigger: 'blur' }], 
        remit_amount: [{ required: true, message: '请输入汇款金额', trigger: 'blur' }],
        full_fee_service: [{ required: true, message: '请选择足额到账服务', trigger: 'blur' }], 
        pay_use_input: [{ required: true, message: '请选择汇款用途', trigger: 'blur' }],
        name_ch: [{ required: true, validator: checkStudentName, trigger: 'blur' }],
        name_first: [{ required: true, validator: checkSurName, trigger: 'blur' }],
        id_card: [{ required: true, validator: checkIdCard, trigger: 'blur' }],
        mobile: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
        student_id: [{ required: true, message: '请输入学生证号', trigger: 'blur' }],
        edu_year: [{ required: true, message: '请选择学制', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入附言', trigger: 'blur' }],
        payer_relation: [{ required: true, message: '请选择与学生关系', trigger: 'blur' }],
        payer_name_ch: [{ required: true, validator: checkStudent2Name, trigger: 'blur' }],
        payer_id_card: [{ required: true, validator: checkIdCard, trigger: 'blur' }],
        payer_mobile: [{ required: true, validator: checkPhone,  trigger: 'blur' }],
        payer_email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
      }
    };
  },
  watch: {
    'ruleForm.payer_relation': {
      handler(newVal, oldVal) { console.log('newVal', newVal, oldVal, this.isCallbackEdit)
        // "与学生关系"是否选中"学生本人"
        this.isChooseThemselves = newVal == '本人' || false;

        if (this.isCallbackEdit && newVal == oldVal) {
          this.$set(this.ruleForm, 'payer_name_ch', this.ruleForm.payer_name_ch);
          this.$set(this.ruleForm, 'payer_id_card', this.ruleForm.payer_id_card);
          this.$set(this.ruleForm, 'payer_mobile', this.ruleForm.payer_mobile);
          this.$set(this.ruleForm, 'payer_email', this.ruleForm.payer_email);
          this.isCallbackEdit = false; // 用过一次标识不再使用，避免是用户自行更改"关系"
        } else if (this.isChooseThemselves) {
          this.$set(this.ruleForm, 'payer_name_ch', this.ruleForm.name_ch);
          this.$set(this.ruleForm, 'payer_id_card', this.ruleForm.id_card);
          this.$set(this.ruleForm, 'payer_mobile', this.ruleForm.mobile);
          this.$set(this.ruleForm, 'payer_email', this.ruleForm.email);
          
        } else {
          if (oldVal !== undefined) {
            this.$set(this.ruleForm, 'payer_name_ch', '');
            this.$set(this.ruleForm, 'payer_id_card', '');
            this.$set(this.ruleForm, 'payer_mobile', '');
            this.$set(this.ruleForm, 'payer_email', '');
          }
        }

      },
      deep: true,
    },
    'ruleForm.name_ch': {
      handler(newVal) {
        // 中文名字的信息同步
        if (this.isChooseThemselves) {
          this.$set(this.ruleForm, 'payer_name_ch', newVal);
        }
      },
      deep: true,
    },
    'ruleForm.id_card': {
      handler(newVal) {
        // 身份证的信息同步
        if (this.isChooseThemselves) {
          this.$set(this.ruleForm, 'payer_id_card', newVal);
        }
      },
      deep: true,
    },
    'ruleForm.mobile': {
      handler(newVal) {
        // 手机号码的信息同步
        if (this.isChooseThemselves) {
          this.$set(this.ruleForm, 'payer_mobile', newVal);
        }
      },
      deep: true,
    },
    'ruleForm.email': {
      handler(newVal) {
        // 电子邮箱的信息同步
        if (this.isChooseThemselves) {
          this.$set(this.ruleForm, 'payer_email', newVal);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getBeforOrderData()
  },
  methods: {
    getBeforOrderData() {
      // 获取院校收款账号
      const college_id = this.getUrlParams('collegeId')
      const order_number = this.getUrlParams('orderNumber') || null
      const order_type = this.getUrlParams('orderType') || 1 // 1新下单 2再次缴费 3编辑订单信息
      beforeOrder({
        college_id,
        order_number,
        order_type,
      }).then((res) => {
        if (Number(res.code) === 20000) {
          const { list, pay_use, relation, remitter, payee, full_fee } = res.data || []

          // "收款账号"选项内容
          if (list.length > 0) {
            this.codeOptions = list;
          }

          // "汇款用户"选项内容
          let payUseList = [];
          if (pay_use.length > 0) {
            pay_use.forEach( v => {
              payUseList.push({
                value: v,
                label: v,
              })
            })
          }
          this.payUseOptions = payUseList;

          // "与学生关系"选项内容
          let payerList = [];
          if (relation.length > 0) {
            relation.forEach( v => {
              payerList.push({
                value: v,
                label: v,
              })
            })
            this.payerOptions = payerList
          }

          // 存储不需要用户填入的信息
          this.apiData = {
            remitter,
            payee,
            full_fee,
          }

          // "修改信息"过来的页面已有order_number,需回填
          const order_number = this.getUrlParams('orderNumber')
          if (order_number) {
            const { stu_info, college_info, order_info } = res.data;
            this.ruleForm = stu_info;
            this.collegeInfo = college_info || {}

            // 返回编辑的时候有order_info
            const orderType = this.getUrlParams('orderType')
            if (orderType == 3 && order_info.college_id) {
              this.ruleForm.account_id = order_info.account_id
              this.ruleForm.account_number = order_info.account_number
              this.ruleForm.full_fee_service = order_info.full_fee_service
              this.ruleForm.remit_amount = order_info.remit_amount
            }

            // 记录是返回编辑的场景
            this.isCallbackEdit = true
          }

          if (typeof this.ruleForm.pay_use == 'string' && this.ruleForm.pay_use.length > 0) {
            this.ruleForm.pay_use_input = this.ruleForm.pay_use.split(',')
          } 
          

        }

      });
    },
    getUrlParams(paramName) {
      let url = decodeURIComponent(window.location.href);
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
    handleClose() {
      // 返回修改
      this.dialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 打开"确认信息"弹窗
          this.dialogVisible = true;

          if (this.ruleForm.other) {
            // 如果填入了"其他 Other"的内容，则放到pay_use字段存储发给接口
            this.ruleForm.pay_use_input.push(this.ruleForm.other);
          }

          this.informationList = this.ruleForm;
        } else {
          console.log("error submit!!");
          this.$message({
            type: 'error',
            message: '表单校验失败，请检查！',
          });
          return false;
        }
      });
    },
    handleAddOrder() {
      // 确认无误，创建订单
      const orderNumber = this.getUrlParams('orderNumber');
      if (orderNumber) {
        // 如果有orderNumber，则是修改信息，需带参请求接口
        this.ruleForm.order_number = orderNumber;
      }
      
      this.ruleForm.college_id = this.getUrlParams('collegeId');
      this.ruleForm.order_type = this.getUrlParams('orderType');

      if (typeof this.ruleForm.pay_use_input == 'object') {
        this.ruleForm.pay_use = this.ruleForm.pay_use_input.join(',')
      } 

      if (this.apiData.full_fee == '') {
        // 接口没有返回full_fee字段，则不需要服务
        this.ruleForm.full_fee_service = 2;
      }
      saveOrder(this.ruleForm).then( (res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          const { order_number } = res.data || '';
          this.$router.push(`/UpdateInfoForm?collegeId=${this.ruleForm.college_id}&orderNumber=${order_number}`);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    isShowOtherInput(params) {
      // 是否显示"其他输入框"让用户填写
      return params && params.indexOf('其他 Other') != -1 || false   
    },
    transferType() {
      // 返回transfer_type值，判断是否显示"Reference Number"、"Account Number"
      const chooseData = this.codeOptions.filter( v => {
        return v.id == this.ruleForm.account_id
      }) || []
      if (chooseData.length) {
        return chooseData[0].transfer_type // 1 //汇款方式 1、国际电汇2、澳洲bpay 3、Bill Payment 4、Online Payment
      } else {
        return 0
      }
    },
    handleSelectAccountId(account_id) {
      const chooseAccountData = this.codeOptions.filter( v => {
        return v.id == account_id
      })
      this.chooseAccountData = chooseAccountData;
      if (chooseAccountData.length) {
        this.currencyCode = chooseAccountData[0].currency_code;
      }
    },
    getPinyinData() {
      // 帮用户填写"学生姓名"的拼音
      getPinyin({
        name: this.ruleForm.name_ch
      }).then( (res) => {
        if (Number(res.code) === 20000) {
          let { name_first, name_last } = res.data
          this.$set(this.ruleForm, 'name_first', name_first)
          this.$set(this.ruleForm, 'name_last', name_last)
        }
      });

    },
    checkIdCardData() {
      // 验证"身份证"是否有效
      checkIdCard({
        name: this.ruleForm.name_ch,
        id_card: this.ruleForm.id_card
      }).then( (res) => {
        if (Number(res.code) !== 20000) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });

    }
  }

}
</script>
<style scoped>
.f-header-fixed .m-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.f-header-fixed .u-steps-tips {
  position: fixed;
  top: 30px;
  z-index: 9;
  width: 100%;
  padding: 20px 0 10px;
  background-color: #fff;
}
.f-header-fixed .main {
  margin-top: 220px;
}
.collegeinfo-title {
  font-size: 16px;
  font-weight: 700;
}
.collegeinfo-title .tips {
  display: inline-block;
  margin-right: 36px;
  width: 150px;
  height: 56px;
  line-height: 56px;
  background-color: #C9F269;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  text-align: center;
  font-weight: 400;
}
.collegeinfo-title .subtitle {
  margin-right: 20px;
}
.collegeinfo-ruleForm .title  {
  margin-top: 40px;
  margin-bottom: 8px;
  font-size: 20px;
}
.collegeinfo-ruleForm .u-infor {
  text-align: center;
}
.collegeinfo-ruleForm .u-infor /deep/ .el-form-item__content {
  width: 100%;
}

.collegeinfo-ruleForm /deep/ .el-form-item {
  margin-bottom: 22px;
}
.collegeinfo-ruleForm /deep/ .el-form-item__label {
  width: 134px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-radius: 8px;
  background-color: #ddd;
  font-size: 16px;
  color: #313131;
}
.collegeinfo-ruleForm /deep/ .el-input--suffix .el-input__inner {
  padding-right: 50px;
}
.collegeinfo-ruleForm /deep/ .el-input__inner:hover {
  border-color: #008cd6;
}
.collegeinfo-ruleForm .f-text-tips /deep/ .el-input__inner::placeholder {
  color: #F56C6C;
}
.collegeinfo-ruleForm /deep/ .el-form-item__content {
  float: left;
  width: 83%;
}
.collegeinfo-ruleForm /deep/ .el-input__inner {
  margin-left: 21px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
}
.collegeinfo-ruleForm /deep/ .el-icon-question {
  font-size: 20px;
  vertical-align: bottom;
}
.collegeinfo-ruleForm /deep/ .el-input__suffix {
  margin-right: -10px;
}
.collegeinfo-ruleForm /deep/ .el-select {
  width: 100%;
}
.collegeinfo-ruleForm /deep/ .el-radio-group {
  margin-left: 20px;
}
.collegeinfo-ruleForm .u-college-radio /deep/ .el-radio.is-bordered {
  width: 372px;
  height: 56px;
  padding-top: 18px;
  font-weight: 400;
}
.collegeinfo-ruleForm /deep/ .el-radio__input {
  float: right;
  margin-left: 10px;
}
.collegeinfo-ruleForm /deep/ .el-radio__inner {
  width: 20px;
  height: 20px;
}
.el-radio.is-bordered.is-checked {
  border-color: #008cd6;
  background-color: #008cd6;
}
.collegeinfo-ruleForm /deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #fff;
}
.collegeinfo-ruleForm .u-college-radio /deep/ .el-radio__label {
  font-size: 16px;
}
.collegeinfo-ruleForm /deep/ .el-icon-warning-outline {
  float: left;
  font-size: 50px;
  color: red;
}
.collegeinfo-ruleForm .u-icon-submit {
  margin-top: 8px;
  width: 530px;
  height: 56px;
  font-size: 16px;
}
.collegeinfo-ruleForm /deep/ .el-form-item__error {
  left: 20px;
  font-size: 14px;
}
.collegeinfo-ruleForm /deep/ .el-select__tags {
  padding-left: 30px;
}
.collegeinfo-ruleForm /deep/ .el-select__tags-text {
  font-size: 16px;
}
.u-flex-layout /deep/ .el-form-item__content{
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.u-flex-layout /deep/ .el-form-item__content div:first-child {
  width: 160%;
}
.u-flex-layout .sub-input {
  margin-left: 20px;
}
.u-question-tips .qa-title {
  font-size: 16px;
  color: #008cd6;
}
.u-question-tips .qa-text{
  color: #BFBFBF;
}
.u-question-tips /deep/ .el-form-item__content {
  line-height: 24px;
}
.u-account-input /deep/ .el-form-item__content {
  padding-left: 21px;
  width: 82.9%;
}
.u-account-input /deep/ .el-input__inner{
  margin-left: 0;
}
.u-account-input /deep/ .el-input__suffix {
  margin-right: 0;
}
/* .u-steps-tips /deep/ .el-step.is-center .el-step__line {
  left: 0;
} */
.submit-text-tips {
  color: #BFBFBF;
}
.submit-text-tips a {
  color: #008cd6;
  text-decoration: none;
}
.u-information-pop /deep/ .el-dialog {
  border-radius: 10px;
}
.u-information-pop /deep/ .el-dialog__header {
  padding-top: 40px;
}
.u-information-pop /deep/ .el-dialog__body {
  padding: 25px 48px 30px;
}
.u-information-pop /deep/ .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
}

.u-information-pop .title {
  margin-bottom: 8px;
  font-size: 20px;
  color: #313131;
}
.u-information-pop .tips-pop-content .box {
  margin-bottom: 44px;
  padding: 20px 30px;
  line-height: 44px;
  border-radius: 8px;
  background-color: #DAEFFE;
  font-size: 16px;
}
.u-information-pop .box-name {
  display: inline-block;
  margin-right: 30px;
  width: 120px;
  color: #008CD6;
}
.u-information-pop .box-content {
  color: #313131;
}
.u-information-pop .u-info {
  font-size: 16px;
  text-align: center;
  color: #313131;
}
.u-information-pop .u-info .tips {
  color: #008CD6;
}
.u-information-pop .u-info .el-button {
  margin: 32px 20px 42px;
  width: 300px;
  height: 56px;
  font-size: 16px;
}
.u-information-pop .u-info .el-button--default {
  border: 2px solid #008CD6;
  color: #008CD6;
}
.u-information-pop .u-info .el-button--primary {
  background-color: #008CD6;
  color: #fff;
}
.collegeinfo-ruleForm .special-item /deep/ .el-form-item__label {
  padding: 6px 10px 0;
  line-height: 22px;
}
.collection-list-box {
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #BFBFBF;
  color: #A0A0A0;
}
.collection-list-box:hover {
  border-color: #008CD6;
}
.collection-list-box .list-header {
  padding: 20px;
}
.collection-list-box .list-footer {
  display: flex;
  padding: 20px;
  background-color: #F2F2F2;
  border-radius: 0 0 8px 8px;
}
.collection-list-box .list-footer span {
  margin-right: 20px;
}
.collection-list-box .name {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #313131;
}
.collection-list-box .subtitle {
  display: block;
  color: #008CD6;
}
.collection-list-box .cardnum {
  display: block;
  width: 400px;
  white-space: normal;
  line-height: 20px;
}
.collection-list-box .tag {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #008CD6;
  border-radius: 8px;
  text-align: center;
  color: #313131;
}
.collection-list-box .context {
  display: inline-block;
  width: 320px;
  vertical-align: middle;
  overflow: hidden;
}
.collection-list-box .u-icon-swift {
  float: left;
  margin-right: 20px;
}
.f-choose-account {
  margin-top: -16px;
  margin-left: 156px;
  width: 908px;
  font-size: 16px;
}
/** 重置下拉框样式 */
.el-select-dropdown__wrap {
  max-height: auto;
}
.el-select-dropdown__item {
  height: auto;
  line-height: 32px;
  padding: 10px 20px;
  font-size: 16px;
}
.collection-list-box {
  width: 827px;
  padding: 0!important;
}
/* .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #008CD6;
} */
.f-display-flex {
  display: flex;
}
.f-display-flex /deep/ .el-form-item__content {
  width: 262px;
}
.u-icon-arrow {
  margin: 20px 28px 0 42px;
  display: inline-block;
  width: 100px;
  height: 16px;
  background: url("../assets/icon_arrow.png") no-repeat;
}
.u-icon-question {
  position: absolute;
  right: -6px;
  top: 16px;
  vertical-align: middle!important;
  color: #C0C4CC;
}
/deep/ .el-step__head.is-finish,
/deep/ .el-step__title.is-finish,
/deep/ .el-step__head.is-process,
/deep/ .el-step__title.is-process {
  color: #008CD6;
}
</style>