<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
// RESET
body {
  font: 12px/1.5 MicrosoftYaHeiLight,sans-serif;
  color:#313131;
}
body,
div,
ul,
p {
  margin: 0;
  padding: 0;
}
img {
  border: 0 none;
  vertical-align: top;
}
a {
  background: transparent;
    &:active,
    &:hover {
      outline: 0 none;
    }
    &:hover {
      text-decoration: none;
    }     
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
ol,
ul,
li {
  list-style: none;
}
// LAYOUT

.main {
  width: 1000px;
  margin: 0 auto;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.router-link-exact-active {
  font-weight: 700;
}
</style>
 