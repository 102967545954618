<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <div class="m-pages-background">
      <h3 class="title">新闻中心<span class="f-block-line"></span></h3>
    </div>
    <!-- content -->
    <div class="m-content main">
      <ul class="m-news-list">
        <li
          v-for="(item, index) in newsListData"
          :key="index"
          class="news-list-box">
          <router-link v-if="item.type == 1" :to="`/NewsInfo?id=${item.id}`" target="_blank" class="list-link"></router-link>
          <a v-else :href="item.go_url" target="_blank" class="list-link"></a>
          <img :src="item.img_url" />
          <p class="news-title">
            {{ item.title }}
          </p>
          <p class="news-oper">
            <span class="fl">
              {{ item.add_time }}
            </span>
            <span class="fr">
              <span>
                <i class="el-icon-view"></i>
                {{ item.views}}
              </span>
              <!-- <span>
                <i class="el-icon-thumb"></i>
                2000
              </span> -->
            </span>
          </p>
        </li>
      </ul>

      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="current_page"
        :page-size="limit"
        :total="total"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <!-- footer -->
    <FooterMod />

  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from "../components/FooterMod.vue";
import { getNewsList } from '@/servers/news.js'

export default {
  name: 'NewsList',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    return {
      newsListData: [],
      total: 0,
      limit: 10,
      current_page: 1,
      total_page: 1,
    };
  },
  mounted() {
    this.getNewsListData()
  },
  methods: {
    getNewsListData() {
      // 获取新闻列表
      getNewsList({
        page: this.currentPage,
      }).then((res) => {
        if (Number(res.code) === 20000) {
          const { list, pages} = res.data
          this.newsListData = list
          
          if (pages) {
            this.limit = pages.limit || 10
            this.total = pages.total || 0
            this.total_page = pages.total_page || 0
            this.current_page = pages.current_page || 0
          }
          
        }

      });
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getNewsListData();
    },
  }

}
</script>
<style scoped>
.m-pages-background {
  height: 120px;
  background: url("../assets/news_bg.jpg") no-repeat center;
  background-size: 100%;
  text-align: center;
}
.m-pages-background .title {
  padding-top: 35px;
  font-size: 28px;
  color: #fff;
}
.m-news-list {
  margin-left: -20px;
  padding-top: 50px;
  overflow: hidden;
  font-size: 16px;
}
.news-list-box {
  float: left;
  position: relative;
  margin: 0 20px 20px 0;
  width: 318px;
  border-radius: 10px;
  border: 1px solid #ddd;
}
.news-list-box img {
  width: 100%;
  height: 213px;
  border-radius: 10px 10px 0 0;
}
.m-news-list .news-title {
  padding: 10px 20px 0;
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.m-news-list .news-oper {
  overflow: hidden;
  padding: 10px 20px;
  font-size: 14px;
  color: #9999;
}
.m-news-list .list-link {
  display: block;
  width: 100%;
  min-height: 327px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-block-line {
  display: block;
  margin: 0 auto;
  width: 50px;
  border-bottom: 4px solid #008CD6;
}
.el-pagination {
  margin-top: 30px;
  text-align: center;
}
</style>