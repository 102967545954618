<template>
  <div>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :before-close="handleCloseDialog"
    width="900px"
    center
    class="u-tips-pop"
  >
    <div class="tips-pop-content">
      <h4 class="title">快速添加 | 院校账户信息</h4>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="add-information-ruleForm"
      >
        <el-form-item prop="college_name">
          <el-input
            v-model="ruleForm.college_name"
            placeholder="请输入院校名称（中/英）"
          ></el-input>
        </el-form-item>
        <el-form-item prop="currency_code">
          <el-select
            v-model="ruleForm.currency_code"
            placeholder="请选择货币币种"
            class="u-select"
          >
            <el-option
              v-for="(item, index) in styleOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="account_name">
          <el-input
            v-model="ruleForm.account_name"
            placeholder="请输写学校收款账户"
          ></el-input>
        </el-form-item>
        <el-form-item prop="from">
          <el-select
            v-model="ruleForm.from"
            placeholder="请选择信息来源"
            class="u-select"
          >
            <el-option
              v-for="(item, index) in fromOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div>
            <p class="tips">请提交含院校账户信息的文件 | <el-button type="text" @click="handleRequirementDialog">文件要求</el-button></p>
            <el-upload
              class="upload-box"
              action=""
              accept=".jpg,.jpeg,.png,.pdf"
              multiple
              :limit="4"
              :file-list="file_list_preview"
              :auto-upload="false"
              :on-remove="onRemoveFile"
              :on-change="onUploadChange"
              :on-exceed="handleExceed"
              list-type="picture">
              <el-button size="small" type="success">+ 上传文件</el-button>
              <div slot="tip" class="el-upload__tip">
                (支持pdf或jpg/png格式，最多上传4个，单个不超过2M)
              </div>
            </el-upload>
          </div>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>

  </el-dialog>


  <el-dialog
    :visible.sync="requirementDialogVisible"
    :close-on-click-modal="false"
    width="80%"
    center
    class="u-tips-pop"
  >
    <div class="u-requirementList">
      <h6 class="title">文件要求</h6>
      <div class="content">
        <p class="subtitle">1、图片需包含以下信息，添加图片账号信息后，可以继续进行缴费:</p>
        <div class="tag-item"
          v-for="(item, index) in requirementList01"
          :key="`info${index}`">
          <span>{{item.name}}</span>
          <span>{{item.info}}</span>
        </div>

        <p class="subtitle">2、部分国家实施国内银行代码，参考如下:</p>
        <div class="tag-item"
          v-for="(item, index) in requirementList02"
          :key="`band${index}`">
          <span>{{item.name}}</span>
          <span>{{item.info}}</span>
        </div>
        
        <p class="subtitle">3、通过下列来源或咨询校方获得:</p>
        <div class="piclist">
          <img src="../assets/require_img01.jpeg" />
          <img src="../assets/require_img02.jpeg" />
          <img src="../assets/require_img03.jpeg" />
        </div>
        
      </div>

    </div>

  </el-dialog>


  <el-dialog
    :visible.sync="weChatDialogVisible"
    :close-on-click-modal="false"
    :before-close="handleCloseDialog"
    width="640px"
    center
    class="u-wechat-pop"
  >
    <div class="tips-pop-content">
      <p class="title">
        <span class="tips">找不到付款机构？</span>
        请添加客户经理微信
      </p>
      <img class="pic" src="../assets/icon_big_wechat.png" />
      <p class="content">
        <img src="../assets/icon_wechat.png" /><span class="sub-title">微信号</span> | <span class="number">BLT202306</span>
      </p>
      <p class="content">
        <img src="../assets/icon_phone.png" /><span class="sub-title">联系电话</span> | <span class="number">400-998-7723</span>
      </p>

    </div>
  </el-dialog>

  </div>
</template>

<script>
import { getCurrencyList, getAccountSources } from '@/servers/index.js'
import { addCollege } from '@/servers/order.js'
export default {
  name: "PopTips",
  props: {
    isShowPop: {
      type: Boolean
    },
  },
  data() {
    return {
      dialogVisible: false,
      requirementDialogVisible: false,
      weChatDialogVisible: false,
      styleOptions: [
        {
          value: 1,
          label: "人民币",
        }
      ],
      fromOptions: [
        {
          value: 1,
          label: "网络",
        },
      ],
      file_list: [],
      file_list_preview: [],
      requirementList01: [
        {
          name: "①学校信息",
          info: "学校名称/官网地址/邮箱logo等",
        },
        {
          name: "②收款账户名",
          info: "Account Name",
        },
        {
          name: "③收款账户号",
          info: "IBAN",
        },
        {
          name: "④Sort Code",
          info: "6位数字清算号",
        },

        {
          name: "⑤SWIFT Code",
          info: "8位或11位银行识别码",
        },
      ],
      requirementList02: [
        {
          name: "国家",
          info: "银行代码",
        },
        {
          name: "美国",
          info: "Routing Number",
        },
        {
          name: "加拿大",
          info: "Routing Number",
        },
        {
          name: "英国",
          info: "Sort Codes",
        },
        {
          name: "澳大利亚",
          info: "BSB number",
        },
        {
          name: "新西兰",
          info: "BSB number",
        },
        {
          name: "印度",
          info: "IFSC Code",
        },
      ],
      ruleForm: {
        college_name: '',
        currency_code: '',
        account_name: '',
        from: '',
        file_list: [],
      },
      rules: {},
    };
  },
  watch: {
    isShowPop: {
      handler(newVal) {
        // this.dialogVisible = newVal
        this.weChatDialogVisible = newVal
      },
      deep: true,
    }
  },
  mounted() {
    this.getCurrencyListData()
    this.getAccountSourcesData()
  },
  methods: {
    getCurrencyListData() {
      // 获取手机区号
      getCurrencyList().then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          const currencyList = []
          list.forEach( v => {
            currencyList.push({
              value: v.code,
              label: v.name
            })
          })
          
          this.styleOptions = currencyList
        }
      });
    },
    getAccountSourcesData() {
      // 获取院校账号信息来源
      getAccountSources().then((res) => {
        if (Number(res.code) === 20000) {
          const list = res.data[0] || []
          const accountList = []
 
          list.forEach( v => {
            accountList.push({
              value: v,
              label: v
            })
          })
          
          this.fromOptions = accountList
        }
      });
    },
    handleRequirementDialog() {
      //查看文件要求
      this.requirementDialogVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        
          addCollege({
            college_name: this.ruleForm.college_name,
            currency_code: this.ruleForm.currency_code,
            account_name: this.ruleForm.account_name,
            from: this.ruleForm.from,
            file: this.file_list,
          }).then( (res) => {
            if (Number(res.code) === 20000) {
              this.$message({
                message: '修改密码成功！' || res.msg,
                type: "success",
              });
              this.$router.push('/LoginRegister');

            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          console.log("error submit!!");
          this.$message({
            type: 'error',
            message: '表单校验失败，请检查！',
          });
          return false;
        }
      });
    },
    handleCloseDialog() {
      // 关闭弹窗回调
      this.$emit('closeCollegeInfoDialog')
      //记录用户关闭了弹框
      sessionStorage.setItem('CLOSEDialog','1');
    },
    onUploadChange(file) {
      const isImage = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'application/pdf');
      const isLt2M = file.size / 2048 / 2048 < 1;
      if (!isImage) {
        this.$message({
          type: 'error',
          message: '格式仅支持pdf或jpg/png，请检查！',
        });
        return false;
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '文件大小不能超过2MB，请检查！',
        });
        return false;
      }
      
      this.file_list.push(file.raw)

      // 预览所需要 file_list_preview
      this.file_list_preview.push({
        uid: file.uid,
        url: file.url,
        name: file.name
      })

    },
    handleExceed() {
      // 上传文件超过数量提示
      this.$message({
        type: 'warning',
        message: '文件数量最多只能上传4个！',
      });
    },
    onRemoveFile(file) {
      // 删除图片
      this.file_list.forEach( (v, index) => {
        if (v.uid === file.uid) {
          this.file_list.splice(index, 1);
        }
      })

      // 预览所需要
      this.file_list_preview.forEach( (v, index) => {
        if (v.uid === file.uid) {
          this.file_list_preview.splice(index, 1);
        }
      })
      
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.u-tips-pop {
  .title {
    margin-bottom: 30px;
    font-size: 24px;
    color: #313131;
    text-align: center;
  }
  .tips-pop-content {
    line-height: 24px;
    text-align: center;
  }
  .u-select,
  .u-submit {
    width: 100%;
  }
  .u-submit {
    margin-bottom: 30px;
  }
}
.u-requirementList {
  font-size: 24px;
  font-weight: 300;
  color: #b0b1b4;
  .subtitle {
    margin: 50px 0 8px;
    color: #3b3b3b;
  }
  .piclist img {
    width: 33%;
  }
}
.tips-pop-content {
  .tips {
    font-size: 16px;
    .el-button {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .el-upload__tip {
    margin-top: -14px;
    font-size: 14px;
    color: #BFBFBF;
  }
}
// .tips-pop-content .el-button {
//   margin: 32px 20px 42px;
//   width: 300px;
//   height: 56px;
//   font-size: 16px;
// }
</style>
<style scoped>
/deep/ .el-dialog {
  border-radius: 10px;
}
/deep/ .el-dialog__header {
  padding-top: 40px;
}
/deep/ .el-dialog__body {
  padding: 25px 48px 30px;
}
/deep/ .el-dialog__title {
  font-size: 24px;
  font-weight: 700;
}
.add-information-ruleForm /deep/ .el-input__inner {
  margin-left: 21px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
}
.upload-box /deep/ .el-upload--picture {
  position: absolute;
  right: 0;
  top: 6px;
}
.add-information-ruleForm /deep/ .el-button--primary{
  width: 100%;
  height: 56px;
  font-size: 16px;
}
.upload-box /deep/ .el-button--success {
  height: 56px;
  background-color: #C9F269;
  border-color: #DCDFE6;
  color: #313131;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
}
.upload-box /deep/ .el-upload-list--picture {
  display: flex;
  margin: 30px 0 40px;
}
.upload-box /deep/ .el-upload-list__item {
  margin-right: 20px;
}
.el-select-dropdown__item {
  height: 42px;
  line-height: 42px;
  font-size: 16px;
}
.upload-box /deep/ .el-upload-list--picture {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px;
}
.upload-box /deep/ .el-upload-list__item {
  margin-right: 20px;
  width: 300px;
  height: 290px;
  padding: 10px;
}
.upload-box /deep/ .el-upload-list__item.is-success .el-upload-list__item-status-label {
  display: none;
}
.upload-box /deep/ .el-upload-list__item .el-icon-close {
  display: block;
  z-index: 1;
}
.upload-box /deep/ .el-upload-list--picture .el-upload-list__item {
  line-height: 290px;
}
.upload-box /deep/ .el-upload-list__item-thumbnail {
  float: none;
  margin-left: 0;
  width: 100%;
  height: auto;
}

.tips-pop-content {
  line-height: 40px;
  font-size: 16px;
  text-align: center;
}
.tips-pop-content .pic {
  width: 280px;
  height: 280px;
}
.f-text-tips {
  color: red;
}
.u-wechat-pop {
  text-align: center;
}
.u-wechat-pop .title{
  margin: 20px 0;
  font-size: 20px;
}
.u-wechat-pop .tips {
  font-size: 20px;
  color: #008CD6;
}
.u-wechat-pop .sub-title {
  display: inline-block;
  width: 90px;
  color: #313131;
}
.u-wechat-pop .number {
  margin-left: 10px;
}
.u-wechat-pop .content {
  margin: 10px auto 0;
  width: 325px;
  text-align: left;
  color: #A0A0A0;
}
.u-wechat-pop .content img {
  margin-right: 20px;
  vertical-align: middle;
}
.u-wechat-pop .tips-pop-content {
  padding-bottom: 30px;
}
</style>
