<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <div class="m-content main">
      <div class="u-email-tips">
        <div v-if="isCheckSuccess">
          <el-button type="success" icon="el-icon-check" circle></el-button>
          <p>账户激活成功：<router-link to="/loginRegister">我要登录</router-link>，您账户已激活，可以返回登录页面进行登录。</p>
        </div>
      </div>
    </div>
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from "../components/FooterMod.vue";
import { checkMail } from '@/servers/mail'

export default {
  name: 'EmailCheck',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    return {
      isCheckSuccess: false
    };
  },
  mounted() {
    this.checkMailData()
  },
  methods: {
    checkMailData() {
      // 获取历史订单
      const secret = this.getUrlParams('secret');
      checkMail({
        secret
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.isCheckSuccess = true;
        }

      });
    },
    getUrlParams(paramName) {
      let url = decodeURIComponent(window.location.href);
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
  }

}
</script>
<style scoped>
.u-email-tips {
  line-height: 60px;
  padding: 200px 0;
  font-size: 20px;
  text-align: center;
}
.u-email-tips a {
  color: #008cd6;
  text-decoration: none;
}
.u-email-tips .el-button.is-circle {
  font-size: 30px;
}
</style>