
import request from "@/utils/request.js";

/**
 * 
 * 获取新闻列表
 * @returns 
 */
export function getNewsList(params) {
  return request({
    method: "get",
    url: "/News/getNewsList",
    params,
  });
}

/**
 * 
 * 获取新闻详情
 * @returns 
 */
export function getNewsInfo(params) {
  return request({
    method: "post",
    url: "/News/getNewsInfo",
    data: params,
  });
}

/**
 * 
 * 获取单页面详情
 * id: 1服务条款 2隐私政策 3服务协议 4常见问题 5联系我们 6合作伙伴 7商务合作 8查看转账指引
 * @returns 
 */
export function getSinglePageInfo(params) {
  return request({
    method: "get",
    url: "/News/getSinglePageInfo",
    params,
  });
}

