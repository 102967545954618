<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <LoginReg />
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from "../components/HeaderMod.vue";
import FooterMod from "../components/FooterMod.vue";
import LoginReg from "../components/LoginReg.vue";
export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  components: {
    HeaderMod,
    LoginReg,
    FooterMod,
  },
};
</script>
