import request from "@/utils/request.js";

/**
 * 
 * 获取院校收款账号
 * @param {*} college_id 
 * @returns 
 */
export function getCollegeAccount(params) {
  return request({
    method: "get",
    url: "/Order/getCollegeAccount",
    params,
  });
}

/**
 * 添加院校收款账号
 * @param {*} college_name 
 * @param {*} currency_code 
 * @param {*} account_name 
 * @param {*} from 
 * @param {*} file_list 
 * @param {*} file_list.url 
 * @param {*} file_list.name
 * @returns 
 */
export function addCollege(params) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: "post",
    url: "/Order/addCollege",
    data: params,
  });
}

/**
 * 提交订单
 * @returns 
 */
export function saveOrder(params) {
  return request({
    method: "post",
    url: "/Order/saveOrder",
    data: params,
  });
}

/**
 * 提交订单文件
 * @returns 
 */
export function saveOrderFile(params) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: "post",
    url: "/Order/saveOrderFile",
    data: params,
  });
}

/**
 * 获取订单信息
 * @returns 
 */
export function getPayOrderInfo(params) {
  return request({
    method: "get",
    url: "/Order/getPayOrderInfo",
    params,
  });
}

/**
 * 获取订单信息
 * @returns 
 */
export function updateOrderStatus(params) {
  return request({
    method: "post",
    url: "/Order/updateOrderStatus",
    data: params,
  });
}

/**
 * 获取历史订单
 * @returns 
 */
export function getOrderList(params) {
  return request({
    method: "get",
    url: "/Order/getOrderList",
    params,
  });
}

/**
 * 获取下单的基础数据（下订单前置）
 * @returns 
 */
export function beforeOrder(params) {
  return request({
    method: "get",
    url: "/Order/beforeOrder",
    params,
  });
}

/**
 * 获取文件信息
 * @returns 
 */
export function getOrderFile(params) {
  return request({
    method: "get",
    url: "/Order/getOrderFile",
    params,
  });
}
