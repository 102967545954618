<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :show-close="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    width="300px"
    center
    class="u-tips-pop">
    <div class="tips-pop-content">
      <h4 class="title">欢迎使用留学e汇！</h4>
      <el-select v-model="user" placeholder="请选择身份" class="u-select">
        <el-option
          v-for="(item, index) in userOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSetRole" class="u-submit">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "PopTips",
  data() {
    return {
      user: 1,
      dialogVisible: false,
      userOptions: [
        {
          value: 1,
          label: "学生",
        },
        {
          value: 2,
          label: "家长",
        },
        {
          value: 2,
          label: "老师",
        },
      ],
    };
  },
  mounted() {
    const roleType = localStorage.getItem('roleType');
    const token = localStorage.getItem('token');
    if (token && !roleType) {
      this.dialogVisible = true;
    }
  },
  methods: {
    handleSetRole() {
      // 存储roleType"角色"
      localStorage.setItem('roleType', this.user);
      this.dialogVisible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.u-tips-pop {
  .title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #313131;
  }
  .tips-pop-content {
    line-height: 60px;
    text-align: center;
  }
  .u-select {
    margin-top: 30px;
    width: 74%;
    margin-left: -37px;
  }
  .u-submit {
    margin-bottom: 30px;
  }
}
</style>
<style scoped>
 .u-tips-pop /deep/ .el-dialog {
  border-radius: 10px;
}
.u-tips-pop /deep/ .el-input__inner {
  margin-left: 21px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
}
.u-tips-pop /deep/ .el-button--primary {
  margin-top: 20px;
  width: 72%;
  height: 56px;
  font-size: 16px;
  background-color: #008cd6;
  border-color: #008cd6;
}
.el-select-dropdown__item {
  height: auto;
  line-height: 32px;
  padding: 10px 20px;
  font-size: 16px;
}
.u-tips-pop /deep/ .el-dialog--center {
  margin-top: 25vh!important;
}
</style>
