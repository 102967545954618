<template>
  <div class="m-content">
    <div class="u-login-bg"></div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      :stretch="true"
      class="u-login-register"
    >
      <el-tab-pane label="手机注册/登录" name="phoneLogin">
        <!-- 手机注册/登录 -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="phone-login-ruleForm"
        >
          <el-form-item prop="phone_number" class="phone">
            <el-select placeholder="+86" v-model="ruleForm.zone_code">
              <el-option
                v-for="item in codeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="ruleForm.phone_number"
              placeholder="请输入手机号码"
            ></el-input>
            <el-button v-if="hasSmsCode" type="info" size="medium" @click="sendSmsCode" disabled>{{smsCodeCount}}秒后重试</el-button>
            <el-button v-else :type="phoneNoPass ? 'info' : 'success'" size="medium" @click="sendSmsCode" :disabled="phoneNoPass">短信验证码</el-button>
          </el-form-item>
          <el-form-item prop="verify_code" class="code">
            <el-input
              v-model="ruleForm.verify_code"
              placeholder="请输入验证码"
            >
              <el-button type="text" slot="suffix" @click="handleChangeActiveName">收不到验证码?</el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="smsSubmitForm('ruleForm')"
              >注册/登录</el-button
            >
            <p class="submit-text-tips">
              <el-checkbox v-model="checked"></el-checkbox>
              登录即同意留学e汇
              <router-link :to="{ path: '/PreviewPage', query: { id: 1 } }">《服务条款》</router-link> 和
              <router-link :to="{ path: '/PreviewPage', query: { id: 2 } }">《隐私政策》</router-link>
            </p>
          </el-form-item>
        </el-form>
        <div></div>
      </el-tab-pane>
      <el-tab-pane label="账号密码登录" name="passwordLogin">
        <!-- 账号密码登录 -->
        <el-form
          :model="passwordRuleForm"
          :rules="rules"
          ref="passwordRuleForm"
          class="phone-login-ruleForm"
        >
          <el-form-item prop="phone_number" class="phone">
            <el-select placeholder="+86" v-model="passwordRuleForm.zone_code">
              <el-option
                v-for="item in codeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="passwordRuleForm.phone_number"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" class="password">
            <el-input
              type="password"
              v-model="passwordRuleForm.password"
              placeholder="请输入密码"
            >
              <router-link slot="suffix" to="/resetPassWord">忘记密码?</router-link>
            </el-input>
            <p v-if="hasLoginTips" class="tips-text">* 默认密码为手机号码后6位，在页面右上角修改密码</p>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="passwordSubmitForm('passwordRuleForm')"
              >登录</el-button
            >
            <p class="submit-text-tips">
              <el-checkbox v-model="checked"></el-checkbox>
              登录即同意留学e汇
              <router-link :to="{ path: '/PreviewPage', query: { id: 1 } }">《服务条款》</router-link> 和
              <router-link :to="{ path: '/PreviewPage', query: { id: 2 } }">《隐私政策》</router-link>
            </p>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getVerifyCode, smsLogin, passwordLogin } from '@/servers/login.js'
import { getZoneList } from '@/servers/index.js'
export default {
  name: "LoginReg",
  data: function () {
    let validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("密码须包含大小写字母和数字，不少于8位"));
      } else {
        callback();
      }
    };
    // 校验手机格式
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[34578][0-9]\d{8}$/;
      if (!value) {
        this.phoneNoPass = true
        callback(new Error("请输入手机号码"));
      } else if (!reg.test(value)) {
        this.phoneNoPass = true
        callback(new Error("手机号码格式不正确，请检查!"));
      } else {
        this.phoneNoPass = false
        callback();
      }
    };
    return {
      checked: true,
      phoneNoPass: true,
      hasSmsCode: false,
      hasLoginTips: true,
      smsCodeCount: '',
      timer: null,
      activeName: 'phoneLogin',
      codeOptions: [
        {
          value: 1,
          label: "86",
        }
      ],
      ruleForm: {
        phone_number: "",
        verify_code: ""
      },
      passwordRuleForm: {
        phone_number: "",
        password: "",
      },
      rules: {
        phone_number: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  created() {
    let SmsCodeEndTime = sessionStorage.getItem('SmsCodeEndTime');
    if (SmsCodeEndTime) {
      this.smsCodeTimeDown(SmsCodeEndTime)
    }

    const loginTips = localStorage.getItem('loginTips');
    if (loginTips) {
      this.hasLoginTips = false
    }

  },
  mounted() {
    this.getZoneListData()
  },
  methods: {
    handleClick() {},
    getZoneListData() {
      // 获取手机区号
      getZoneList().then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          const zoneList = []
          list.forEach( v => {
            zoneList.push({
              value: v.zone,
              label: v.name
            })
          })
          
          this.codeOptions = zoneList

          // 默认先帮用户选择第一个
          this.ruleForm.zone_code = zoneList[0].value;
          this.passwordRuleForm.zone_code = zoneList[0].value;
        }
      });
    },
    sendSmsCode() {
      let clickSmsCodeTime = new Date().getTime() + 60000;
      sessionStorage.setItem('SmsCodeEndTime', JSON.stringify(clickSmsCodeTime));

      // 发送验证码
      getVerifyCode({
        zone_code: this.ruleForm.zone_code,
        phone_number: this.ruleForm.phone_number,
        type: 1 // 短信类型 1注册 2忘记密码 3更换手机 4其他
      }).then((res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          this.hasSmsCode = true;
          this.smsCodeTimeDown(clickSmsCodeTime);

        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });

    },
    smsCodeTimeDown(time) {
      if (!this.timer) {
        this.smsCodeCount = Math.ceil((JSON.parse(time) - new Date().getTime()) /1000);
        this.timer = setInterval( () => {
          if (this.smsCodeCount > 0) {
            this.smsCodeCount --;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            sessionStorage.removeItem('SmsCodeEndTime');
            this.hasSmsCode = false;
          }
        }, 1000)
      }
    },
    smsSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 登录即同意
          this.checked = true;

          // 请求API
          smsLogin( {
            zone_code: this.ruleForm.zone_code,
            phone_number: this.ruleForm.phone_number,
            verify_code: this.ruleForm.verify_code
          }).then((res) => {
            if (Number(res.code) === 20000) {
              this.$message({
                message: res.msg,
                type: "success",
              });

              this.loginSuccess(res)
              
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    passwordSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 登录即同意
          this.checked = true;

          // 请求API
          passwordLogin({
            zone_code: this.passwordRuleForm.zone_code,
            phone_number: this.passwordRuleForm.phone_number,
            password: this.passwordRuleForm.password
          }).then((res) => {
              if (res.code === 20000) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });

                this.loginSuccess(res)

              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChangeActiveName() {
      // "收到验证码?" 帮用户切换tab至"账号密码登录"
      this.activeName = 'passwordLogin';
    },
    loginSuccess(res) {
      const { user_info } = res.data
      const nickname = user_info.nickname || 'e汇用户'
      const userToken = res.token || ''
      // 存储用户信息相关数据
      this.$store.commit('SET_TOKEN', userToken);
      this.$store.commit('SET_NICKNAME', nickname);

      localStorage.setItem('loginTips', true);

      this.callbackPages()
    },
    callbackPages() {
      // 登录成功后返回上一页
      const url = location.hash
      if (url.indexOf('?redirect=') != -1) {
        // "需要登录"的页面跳转过来的登录的，登录成功后需跳转到对应的页面
        const pagesUrl = url.replace('#/loginRegister?redirect=', '')
        const decodeUrl = decodeURIComponent(pagesUrl)
        this.$router.push(decodeUrl)
      } else {
        window.history.back();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.m-content {
  overflow: hidden;
}
.u-login-bg {
  float: left;
  width: 50%;
  height: 500px;
  background: url("../assets/login_bg.png") no-repeat top right;
  background-size: cover;
}
.u-login-register {
  float: left;
  max-width: 440px;
  padding: 40px 0 0 50px;
  width: 45%;
  .phone {
    .el-select {
      margin-right: 2%;
      max-width: 124px;
    }
  }
  .tips-text {
    color: #606266;
  }
  .el-input__suffix {
    a {
      font-size: 18px;
      color: #313131;
      text-decoration: none;
    }
  }
}
.submit-text-tips {
  padding-top: 10px;
  text-align: center;
  a {
    color: #008cd6;
    text-decoration: none;
  }
}
</style>
<style scoped>
.u-login-register /deep/ .el-tabs__nav {
  padding-bottom: 10px;
}
.u-login-register /deep/ .el-tabs__item {
  padding-right: 50px;
  font-size: 22px;
  font-weight: 400;
  color: #7D7D7D;
}
.u-login-register /deep/ .el-button--primary{
  width: 100%;
  font-size: 16px;
}
.u-login-register /deep/ .el-button,
.u-login-register /deep/ .el-input__inner {
  height: 56px;
}
.u-login-register /deep/ .el-input__inner {
  font-size: 16px;
}
.u-login-register /deep/ .el-button--info {
  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
}
.u-login-register /deep/ .el-button--success {
  margin-left: 12px;
  background-color: #d2ef7c;
  border-color: #DCDFE6;
  color: #313131;
  font-weight: 400;
  font-size: 14px;
}
.u-login-register /deep/ .el-tabs__item.is-active {
  color: #313131;
}
.u-login-register /deep/ .el-tabs__active-bar {
  background-color: #3a8cd0;
}
.u-login-register /deep/ .el-input__suffix {
  line-height: 56px;
  right: 10px;
}

.u-login-register /deep/ .el-form-item {
  margin-bottom: 42px;
}
.u-login-register .phone /deep/ .el-form-item__content {
  display: flex;
}
.u-login-register /deep/ .el-tabs__header {
  margin-bottom: 60px;
}
.el-select-dropdown__item {
  height: 42px;
  line-height: 42px;
  font-size: 16px;
}
.u-login-register .el-input__suffix .el-button--text {
  font-weight: 400;
  font-size: 16px;
  color: #313131;
}
</style>
