<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
      <!-- 进度条 -->
      <el-steps :active="2" align-center class="u-steps-tips">
        <div class="main" style=" display: flex; margin: 50px auto 0; width: 1400px;">
          <el-step title="填写信息" icon="el-icon-success"></el-step>
          <el-step title="上传资料" icon="el-icon-success"></el-step>
          <el-step title="缴费支付" icon="el-icon-remove"></el-step>
        </div>
      </el-steps>
      <!-- 填写信息 -->
      <div class="m-content main">
        <div class="update-order-number">
          <span class="name">订单编号</span>
          <span class="text">{{ orderNumber }}</span>
        </div>
        <h5 class="update-order-title">上传文件</h5>
        <div class="update-order-content">
          <div>
            <el-button type="info" @click="submitForm('ruleForm')">上传缴费证明</el-button>
            <span>
              <i class="u-icon-moreinfo"></i>
              <span @click="handleInfoPop" class="more-info">
                查看示例
              </span>
            </span>
          </div>
          <div class="upload-box">
            <el-upload
              action=""
              accept=".jpg,.jpeg,.png,.pdf"
              multiple
              :limit="4"
              :file-list="file_list_preview"
              :auto-upload="false"
              :on-remove="onRemoveFile"
              :on-change="onUploadChange"
              :on-exceed="handleExceed"
              list-type="picture">
              <i class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">(支持pdf或jpg/png格式，最多上传4个，单个不超过2M)</div>
            </el-upload>
            <el-button :type="file_list_preview.length === 0 ? 'info' : 'primary'" @click="submitForm" class="u-icon-submit" :disabled="file_list_preview.length === 0">确认提交</el-button>
          </div>
        </div>
      </div>

      <el-dialog
        title="示例文件"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="900px"
        center
        class="u-information-pop"
      >
        <div class="tips-pop-content">
          <span class="text-bold">上传文件要求</span>（按照监管部门要求，我们需要您上传留学缴费证明）
          <p class="text-content">要求：学生账户里账单、录取通知书、美国I20表格等完整文件（要求但不仅限于此类缴费证明文件）。上传图片或PDF文件均可，要求字迹清晰可辨认，必须包含<span class="text-tips">学校名称</span>、<span class="text-tips">缴费金额</span>、<span class="text-tips">缴费时间</span>。如果您找不到证明材料，请联系我们 400-998-7823。</p>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClickTab" :stretch="true">
          <el-tab-pane label="美国120表格" name="first">
            <i class="u-icon-tab120"></i>
          </el-tab-pane>
          <el-tab-pane label="录取通知书截图" name="second">
            <i class="u-icon-tabband"></i>
          </el-tab-pane>
          <el-tab-pane label="缴费金额截图" name="third">
            <i class="u-icon-tabmessage"></i>
          </el-tab-pane>
        </el-tabs>
        <div class="u-info">
          <el-button type="primary" @click="handleClose">关闭弹窗</el-button>
        </div>

      </el-dialog>
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from "../components/FooterMod.vue";
import { saveOrderFile, getOrderFile } from '@/servers/order.js'

export default {
  name: 'UpdateInfoForm',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    return {
      activeName: 'first',
      dialogVisible: false,
      file_list: [],
      file_list_preview: [],
      hasApiFile: false,
      old_file: [], // 重新编辑的时候用这个存储给接口
      orderNumber: null,
    };
  },
  mounted() {
    this.orderNumber = this.getUrlParams('orderNumber');
    if (this.orderNumber) {
      this.getOrderFileData()
    }
  },
  methods: {
    handleClose() {
      // 关闭弹窗
      this.dialogVisible = false;
    },
    handleClickTab(tab, event) {
      // 切换tab
      console.log(tab, event)
    },
    handleInfoPop() {
      // 查看示例
      this.dialogVisible = true
    },
    onUploadChange(file) {
      const isImage = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'application/pdf');
      const isLt2M = file.size / 2048 / 2048 < 1;
      if (!isImage) {
        this.$message({
          type: 'error',
          message: '格式仅支持pdf或jpg/png，请检查！',
        });
        return false;
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '文件大小不能超过2MB，请检查！',
        });
        return false;
      }
      
      this.file_list.push(file.raw)

      // 预览所需要 file_list_preview
      this.file_list_preview.push({
        uid: file.uid,
        url: file.url,
        name: file.name
      })
      
    },
    handleExceed() {
      // 上传文件超过数量提示
      this.$message({
        type: 'warning',
        message: '文件数量最多只能上传4个！',
      });
    },
    onRemoveFile(file) {
      // 删除图片
      this.file_list.forEach( (v, index) => {
        if (v.uid === file.uid) {
          this.file_list.splice(index, 1);
        }
      })

      // 预览所需要
      this.file_list_preview.forEach( (v, index) => {
        if (v.uid === file.uid) {
          this.file_list_preview.splice(index, 1);
        }
      })

      if (this.orderNumber) {
        // 重新编辑时，原本上传过给接口的文件删除的话对应也得删除给接口
        this.old_file.forEach( (v, index) => {
          if (v.uid === file.uid) {
            this.old_file.splice(index, 1);
          }
        })

      }
      
    },
    submitForm() {
      
      let params = {
        order_number: this.orderNumber,
        file: this.file_list,
        old_file: this.old_file,
      }
      
      if (this.hasApiFile) {
        // 曾经有上传过文件，更改则需传给接口
        this.old_file = this.old_file.filter ( v => {
          return v.id
        })
        params.old_file = this.old_file
      }

      saveOrderFile(params).then( (res) => {
        if (Number(res.code) === 20000) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          const collegeId = this.getUrlParams('collegeId');
          
          this.$router.push(`/PaymentForm?collegeId=${collegeId}&orderNumber=${this.orderNumber}`);
        }
      });
    },
    getUrlParams(paramName) {
      let url = decodeURIComponent(window.location.href);
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
    getOrderFileData() {
      getOrderFile({
        order_number: this.orderNumber
      }).then((res) => {
        if (Number(res.code) === 20000) {
          let { file } = res.data || []
          if (file.length > 0) {

            // 存储之前保存过的文件，提交时给新接口处理
            this.old_file = file;
            this.hasApiFile = true

            // 预览所需要的file_list_preview
            this.file_list_preview = file;

          }
        }

      });
    },
  }

}
</script>
<style scoped>
.update-order-number {
  margin-bottom: 40px;
  height: 56px;
  line-height: 56px;
  padding: 0 20px;
  border: 1px solid #008CD6;
  border-radius: 8px;
  font-size: 16px;
}
.update-order-number .name {
  margin-right: 75px;
  font-weight: 700;
  color: #008CD6;
}
.update-order-title {
  margin-bottom: 10px;
  font-size: 20px;
}
.update-order-content .el-button--info {
  margin-right: 30px;
  width: 160px;
  height: 56px;
  font-size: 16px;
  background-color: #ddd;
  border-color: #ddd;
  color: #313131;
  font-weight: 400;
}
.update-order-content .more-info {
  font-size: 16px;
  color: #008CD6;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
}
.update-order-content .upload-box {
  margin: 16px 0 40px;
  padding: 54px 60px;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  text-align: center;
}
.update-order-content .el-icon-plus {
  font-size: 34px;
}
.update-order-content .el-upload__tip {
  font-size: 14px;
  color: #BFBFBF;
}
.update-order-content .u-icon-submit {
  margin-top: 8px;
  width: 530px;
  height: 56px;
  font-size: 16px;
  
}
.update-order-content .el-button--primary {
  background-color: #008CD6;
}
.update-order-content .upload-box /deep/ .el-upload-list--picture {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px;
}
.update-order-content .upload-box /deep/ .el-upload-list__item-thumbnail {
  float: none;
  margin-left: 0;
  width: 100%;
  height: auto;
}
.update-order-content .upload-box /deep/ .el-upload-list__item {
  margin-right: 20px;
  width: 300px;
  height: 290px;
  padding: 10px;
}
.u-icon-moreinfo {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 20px;
  background: url("../assets/more_info@2x.png") no-repeat;
  background-size: 100%;
}
.u-information-pop /deep/ .el-dialog {
  border-radius: 10px;
}
.u-information-pop /deep/ .el-dialog__header {
  padding-top: 40px;
}
.u-information-pop /deep/ .el-dialog__body {
   padding: 25px 48px 30px;
}
.u-information-pop /deep/ .el-dialog__title {
  font-size: 24px;
  font-weight: 700;
}
.u-information-pop .u-info {
  text-align: center;
}
.u-information-pop .u-info .el-button {
  margin: 32px 20px 42px;
  width: 300px;
  height: 56px;
  font-size: 16px;
}
.tips-pop-content {
  margin-bottom: 44px;
  padding: 30px;
  line-height: 30px;
  border-radius: 8px;
  background-color: #DAEFFE;
  font-size: 16px;
}
.tips-pop-content .text-bold {
  font-weight: 700;
}
.tips-pop-content .text-tips {
  color: #008CD6;
}
.tips-pop-content .text-content {
  margin-top: 20px;
}
.u-information-pop /deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: 400;
  color: #535353;
}
.u-information-pop /deep/ .el-tabs__item.is-active {
  font-weight: 700;
  color: #008CD6;
}
.u-information-pop /deep/ .el-tabs__active-bar {
  background-color: #008CD6;
}
.u-icon-tab120 {
  display: block;
  width: 800px;
  height: 980px;
  background: url("../assets/tab_120.jpeg") no-repeat;
  background-size: 100%;
}
.u-icon-tabband {
  display: block;
  width: 800px;
  height: 880px;
  background: url("../assets/tab_band.jpeg") no-repeat;
  background-size: 100%;
}
.u-icon-tabmessage {
  display: block;
  width: 800px;
  height: 960px;
  background: url("../assets/tab_message.jpeg") no-repeat;
  background-size: 100%;
}
.update-order-content /deep/ .el-upload-list__item.is-success .el-upload-list__item-status-label {
  display: none;
}
.update-order-content /deep/ .el-upload-list__item .el-icon-close {
  display: block;
  z-index: 1;
}
.update-order-content /deep/ .el-upload-list--picture .el-upload-list__item {
  line-height: 290px;
}
/deep/ .el-step__head.is-finish,
/deep/ .el-step__title.is-finish {
  color: #008CD6;
}
/deep/ .el-step__head.is-process,
/deep/ .el-step__title.is-process {
  color: #C0C4CC;
}
/deep/ .el-step__head.is-process {
  color: #C0C4CC;
  border-color: #C0C4CC;
}
</style>