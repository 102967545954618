<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <div class="m-content main">
      <h5 class="history-list-title">历史订单</h5>
      <div class="history-list-content">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          :stretch="true">
          <el-tab-pane
          v-for="(item, index) in historyTabs"
          :key="index"
          :label="item.title"
          :name="item.name">
          </el-tab-pane>
        </el-tabs>
        
        <!-- 筛选 -->
        <el-popover
          placement="right"
          width="400"
          trigger="click">
          <el-date-picker
            v-model="dateTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleChooseDateTime">
          </el-date-picker>
          <el-button type="text" slot="reference" class="u-icon-screen">筛选 <i class="el-icon-arrow-down"></i></el-button>
        </el-popover>

        <el-table :data="historyListData" @row-click="handleRePay" stripe style="width: 100%">
          <el-table-column prop="remit_amount" label="订单金额" width="180">
          </el-table-column>
          <el-table-column prop="total_amount" label="汇款金额" width="180">
          </el-table-column>
          <el-table-column prop="pay_use" label="汇款用途"> </el-table-column>
          <el-table-column prop="add_time" label="下单时间"> </el-table-column>
          <el-table-column prop="status_str" label="订单状态"> </el-table-column>
          <el-table-column label="再次缴费">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleReInfo(scope.row)">再次缴费</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="current_page"
          :page-size="limit"
          :total="total"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMod from "../components/HeaderMod.vue";
import { getOrderList } from '@/servers/order.js';
export default {
  name: "HistoryList",
  props: {
    msg: String,
  },
  components: {
    HeaderMod,
  },
  data() {
    return {
      activeName: 'all',
      historyListData: [],
      dateTime: [],
      total: 0,
      limit: 10,
      current_page: 1,
      total_page: 1,
      tabStatus: 0,
      historyTabs: [
        {
          title: '全部记录',
          name: 'all',
        },
        {
          title: '已完成',
          name: 'done',
        },
        {
          title: '待付款',
          name: 'obligation',
        },
        {
          title: '已取消',
          name: 'canceled',
        },
        {
          title: '已退款',
          name: 'refunded',
        },
      ]
    };
  },
  mounted() {

    // 如果URL带status参数过来，则选中对应的tabs
    const status = this.getUrlParams('status');
    if (status == 3) {
        // 3为取消
      this.activeName = 'canceled';
      this.tabStatus = 3;
      this.getOrderListData();
    } else {
      this.getOrderListData();
    }

  },
  methods: {
    getOrderListData() {
      // 获取历史订单
      let params = {
        status: this.tabStatus, // 0-待支付 1-已支付 2-已取消 3已退款
        page: this.currentPage,
        limit: this.limit,
      }
      if (this.dateTime) {
        params.start_time = this.dateTime[0]
        params.end_time = this.dateTime[1]
      }
      getOrderList(params).then((res) => {
        if (Number(res.code) === 20000) {
          const { list, pages } = res.data || []
          this.historyListData = list;
          
          if (pages) {
            this.limit = pages.limit || 10
            this.total = pages.total || 0
            this.total_page = pages.total_page || 0
            this.current_page = pages.current_page || 0
          }

        }

      });
    },
    handleClick(tab) {
      // 切换tab获取不同的数据
      this.tabStatus = tab.index
      this.getOrderListData(this.tabStatus)
      
    },
    handleChooseDateTime() {
      // 筛选 - 日期
      this.getOrderListData(this.dateTime)
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getOrderListData();
    },
    handleReInfo(params) {
      const collegeId = params.college_id;
      const orderNumber = params.order_number;
      let routerUrl = this.$router.resolve({
        path: `/CollegeInfoForm?collegeId=${collegeId}&orderNumber=${orderNumber}&orderType=2`
      });
      window.open(routerUrl.href, '_blank');

      // this.$router.push(`/CollegeInfoForm?collegeId=${collegeId}&orderNumber=${orderNumber}&orderType=2`); // order_type: 1新下单 2再次缴费 3编辑订单信息
    },
    handleRePay(params) {
      const collegeId = params.college_id;
      const orderNumber = params.order_number;
      let routerUrl = this.$router.resolve({
        path: `/PaymentForm?collegeId=${collegeId}&orderNumber=${orderNumber}&orderType=2`
      })
      window.open(routerUrl.href, '_blank');

      // this.$router.push(`/PaymentForm?collegeId=${collegeId}&orderNumber=${orderNumber}&orderType=2`); // order_type: 1新下单 2再次缴费 3编辑订单信息
    },
    getUrlParams(paramName) {
      let url = decodeURIComponent(window.location.href);
      let params = url.split('?')[1];
      let paramsObj = {}
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[paramName];
    },
  },
};
</script>
<style scoped>
.history-list-title {
  padding: 50px 0 30px;
  text-align: center;
  font-size: 28px;
}
.history-list-content {
  position: relative;
  font-size: 16px;
}
.history-list-content .el-table {
  font-size: 16px;
}
.history-list-content /deep/ .el-table th {
  font-weight: 400;
  color: #A0A0A0;
}
/* .history-list-content /deep/ .el-table thead {
  color: #313131;
} */
.history-list-content /deep/ .el-tabs__item {
  font-size: 16px;
}
.history-list-content /deep/ .el-tabs__item.is-active {
  color: #008cd6;
}
.history-list-content /deep/ .el-tabs__active-bar {
  background-color: #008cd6;
}
.history-list-content .el-table tr {
  cursor: pointer;
}
.el-pagination {
  margin-top: 30px;
  text-align: center;
}
.u-icon-screen {
  position: absolute;
  right: 0;
  top: -3.3px;
  width: 122px;
  font-size: 16px;
  color: #008cd6;
  border-bottom: 2px solid #e4e7ec;
  border-radius: 0;
}
.el-tabs {
  width: 90%;
}
</style>
