import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../pages/HomePage"
import LoginRegister from "../pages/LoginRegister"
import SetPassWord from "../pages/SetPassWord";
import ResetPassWord from "../pages/ResetPassWord";
import CollegeInfoForm from '../pages/CollegeInfoForm';
import UpdateInfoForm from '../pages/UpdateInfoForm';
import PaymentForm from '../pages/PaymentForm';
import UserCenter from '../pages/UserCenter';
import HistoryList from '../pages/HistoryList';
import NewsList from '../pages/NewsList';
import NewsInfo from '../pages/NewsInfo';
import AboutUs from '../pages/AboutUs';
import EmailCheck from '../pages/EmailCheck';
import InfoPage from '../pages/InfoPage';
import PreviewPage from '../pages/PreviewPage';
Vue.use(VueRouter);
const routes = [

  {
    path: "/",
    name: "首页",
    component: HomePage,
  },
  {
    path: "/LoginRegister",
    name: "注册/登录",
    component: LoginRegister,
  },
  {
    path: "/setpassword",
    name: "修改密码",
    component: SetPassWord,
  },
  {
    path: "/resetpassword",
    name: "重新设置密码",
    component: ResetPassWord,
  },
  {
    path: "/CollegeInfoForm",
    name: "我要缴费",
    component: CollegeInfoForm,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/UpdateInfoForm",
    name: "上传资料",
    component: UpdateInfoForm,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/PaymentForm",
    name: "缴费支付",
    component: PaymentForm,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/UserCenter",
    name: "个人中心",
    component: UserCenter,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/HistoryList",
    name: "历史订单",
    component: HistoryList,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/NewsList",
    name: "新闻中心",
    component: NewsList,
  },
  {
    path: "/NewsInfo",
    name: "新闻详情",
    component: NewsInfo,
  },
  {
    path: "/AboutUs",
    name: "关于我们",
    component: AboutUs,
  },
  {
    path: "/EmailCheck",
    name: "邮箱验证",
    component: EmailCheck,
  },
  {
    path: "/InfoPage",
    name: "共用的自定义页面",
    component: InfoPage,
  }, 
  {
    path: "/PreviewPage",
    name: "预览文件",
    component: PreviewPage,
  }
];
const router = new VueRouter({
  // base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
});

// 登录拦截
router.beforeEach((to, from, next) => {

  if (to.meta.requireAuth) {
    if (localStorage.token) {
      next();
    } else {
      next({
        path: '/loginRegister',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

export default router;
