<template>
  <div class="m-content home-background">
    <h2 class="u-slogan">留学缴费, 就用留学e汇</h2>
    <h3 class="u-slogan-mini">院校直达，足额到账，省心又放心！</h3>
    <div class="u-select-educat">
      <!-- 搜索 -->
      
      <el-autocomplete
        popper-class="my-autocomplete"
        :class="{'f-no-search' : !hasSearch}"
        :popper-append-to-body="false"
        v-model="state"
        @focus='tip()'
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入收款院校名称（中/英）"
        @select="handleSelect">
        <i
          class="el-icon-search"
          slot="suffix">
        </i>
        <template slot-scope="{ item }">
          <div v-if="item.data_null" class="u-search-nulldata">
            <span class="f-nulldata-text">无该院校收款信息?</span>
            <span class="f-nulldata-add">
              <i class="el-icon-right"></i>
              去添加
            </span>
          </div>
          <div v-else class="u-search-bar">
            <span class="search-text">
              <i class="u-icon-search-logo"></i>
              <span class="name">{{ item.english_name }}</span>
              <span class="sub-name">{{ item.college_name }}</span>
            </span>
            <i class="u-icon-search-sh">
              <img :src="item.img_url" />
            </i>
          </div>
        </template>

      </el-autocomplete>

    </div>
    <p class="u-pay-tips">
      <i class="u-icon-paymore">合作伙伴 |</i>
      <i class="u-icon-epay" title="银联支付"></i>
      <i class="u-icon-wechat" title="微信支付"></i>
      <i class="u-icon-alipay" title="支付宝支付"></i>
    </p>

    <AddCollegeInfo :isShowPop="isShowPop" @closeCollegeInfoDialog="hasCloseCollegeInfoDialog" />
  </div>
</template>

<script>
import AddCollegeInfo from "./AddCollegeInfo.vue"
import { getCollegeList } from '@/servers/index.js'
export default {
  name: "ContentMod",
  components: {
    AddCollegeInfo,
  },
  data() {
    return {
      searchValue: '',
      loading: false,
      searchDataNull: false,
      searchOptions: [],
      state: '',
      isShowPop: false,
      hasSearch: false,
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      this.hasSearch = false
      // 过滤空格，只有值才可以发起接口请求
      queryString = queryString ? queryString.trim() : ''
      if (queryString == '') {
        return false;
      }
      this.hasSearch = true
      getCollegeList({
        keyword: queryString
      }).then((res) => {
        if (Number(res.code) === 20000) {
          const { list } = res.data || []
          if (list.length) {
            this.searchOptions = list
            var restaurants = this.searchOptions;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              cb(results);
            }, 1000 * Math.random());

          } else {
            let data = [{
              data_null: true
            }];
            cb(data);

          }
        }
      });
    },
    createStateFilter(queryString) {
      return (state) => {
        //console.log('state.english_name', state.english_name, queryString)
        return (state.college_name.toLowerCase().indexOf(queryString.toLowerCase()) === 0 || 
          state.english_name.includes(queryString));
      };
    },
    handleSelect(data) {
      // 无数据，去添加"院校"
      if (data.data_null) {
        this.isShowPop = data.data_null || false
      } else {
        this.$router.push(`/CollegeInfoForm?collegeId=${data.id || 0}&collegeName=${data.college_name}&englishName=${data.english_name}&orderType=1`) // order_type: 1新下单 2再次缴费 3编辑订单信息
      }
    },
    isHasLogin() {
      // 是否有登录
      return localStorage.getItem('token')
    },
    hasCloseCollegeInfoDialog() {
      this.isShowPop = false;
    },
    tip(){
      //判断是否关闭了弹框，关闭了就不再弹出来
      let is_close = sessionStorage.getItem('CLOSEDialog');
      if(is_close!=='1'){
        this.isShowPop = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.m-content {
  text-align: center;
}
.u-slogan {
  font-size: 50px;
  color: #008cd6;
}
.u-slogan-mini {
  font-size: 25px;
  font-weight: 100;
  color: #313131;
}
.u-select-educat {
  font-size: 28px;
}
.u-pay-tips {
  font-size: 22px;
  font-weight: 700;
  color: #008cd6;
}
.u-pay-tips {
  i {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
  }
}
.u-icon-epay {
  width: 39px;
  height: 21px;
  background: url("../assets/epay@2x.png") no-repeat;
  background-size: 100%;
}
.u-icon-wechat {
  width: 37px;
  height: 30px;
  background: url("../assets/wechat@2x.png") no-repeat;
  background-size: 100%;
}
.u-icon-alipay {
  width: 28px;
  height: 28px;
  background: url("../assets/alipay@2x.png") no-repeat;
  background-size: 100%;
}
.u-icon-paymore {
  font-style: inherit;
}
.home-background {
  padding: 140px 0;
  background: url("../assets/home_background.png") no-repeat;
  background-size: 100%;
}
</style>
<style scoped>
.u-select-educat {
  margin: 30px 0;
}
.u-select-educat /deep/ .el-input__inner {
  width: 750px;
  height: 60px;
  font-size: 16px;
  background: none;
  border: 2px solid #008cd6;
  border-radius: 5px;
}
.u-select-educat /deep/ .el-icon-search {
  margin: 16px 10px 0 0;
  font-size: 26px;
}
.u-search-bar {
  overflow: hidden;
  padding: 10px;
  font-weight: 300;
  border-bottom: 1px solid #D2D2D2;
}
.u-search-bar .name {
  display: block;
  font-size: 18px;
}
.u-search-bar .sub-name {
  font-size: 14px;
  color: #A0A0A0;
}
.u-icon-search-logo {
  float: left;
  margin: 6px 16px 14px 0;
  display: block;
  width: 30px;
  height: 26px;
  background: url("../assets/icon_search_logo.png") no-repeat;
  background-size: 100%;
}
.u-icon-search-sh {
  float: right;
  display: block;
  width: 44px;
  height: 28px;
  margin-top: 6px;
}
.u-icon-search-sh img {
  width: 100%;
}
.u-search-bar .search-text {
  float: left;
  width: 80%;
}
.f-border-bottom-none {
  border-bottom: none;
}
.u-search-nulldata {
  padding: 10px;
  font-size: 16px;
  overflow: hidden;
}
.f-nulldata-text {
  float: left;
}
.f-nulldata-add {
  float: right;
  color: #008cd6;
}
.el-autocomplete-suggestion__list li:last-child .u-search-bar {
  border-bottom: none;
}
.my-autocomplete .search-text {
  line-height: 28px;
  text-align: left;
}
.f-no-search /deep/ .el-autocomplete-suggestion {
  display: none;
}
</style>
