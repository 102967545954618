<template>
  <div class="g-container">
    <!-- header -->
    <HeaderMod />
    <!-- content -->
    <div class="m-content main set-password-box">
      <h5 class="set-password-title">修改密码</h5>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="set-password-ruleForm">
        <el-form-item prop="oldpassword">
          <el-input :type="showPw.old_password ? 'text' : 'password'" v-model="ruleForm.old_password" placeholder="请输入旧的密码">
            <i v-if="!showPw.old_password" slot="suffix" class="u-icon-hidepassword" @click="handleView('old_password')"></i>
            <i v-else slot="suffix" class="el-icon-view" @click="handleHide('old_password')"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input :type="showPw.password ? 'text' : 'password'" v-model="ruleForm.password" placeholder="请输入新的密码">
            <i v-if="!showPw.password" slot="suffix" class="u-icon-hidepassword" @click="handleView('password')"></i>
            <i v-else slot="suffix" class="el-icon-view" @click="handleHide('password')"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="newpassword">
          <el-input :type="showPw.confirm_password ? 'text' : 'password'" v-model="ruleForm.confirm_password" placeholder="请确认新密码">
            <i v-if="!showPw.confirm_password" slot="suffix" class="u-icon-hidepassword" @click="handleView('confirm_password')"></i>
            <i v-else slot="suffix" class="el-icon-view" @click="handleHide('confirm_password')"></i>

            <el-tooltip slot="suffix" content="* 密码须包含大小写字母和数字，不少于8位" placement="top" effect="dark">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- footer -->
    <FooterMod />
  </div>
</template>

<script>
import HeaderMod from '../components/HeaderMod.vue'
import FooterMod from '../components/FooterMod.vue'
import { setPassword } from '../servers/login.js'

export default {
  name: 'SetPassWord',
  props: {
    msg: String
  },
  components: {
    HeaderMod,
    FooterMod
  },
  data() {
    let validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("密码须包含大小写字母和数字，不少于8位"));
      } else {
        if (this.ruleForm.confirm_password !== "") {
          this.$refs.ruleForm.validateField("newpassword");
        }
        callback();
      }
    };
    let validatePassNew = (rule, value, callback) => {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else if (!reg.test(value)) {
        callback(new Error("密码须包含大小写字母和数字，不少于8位"));
      } else {
        callback();
      }
    };
    return {
      showPw: {
        old_password: false,
        password: false,
        confirm_password: false,
      },
      ruleForm: {
        password: '',
        newpassword: '',
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirm_password: [{ validator: validatePassNew, trigger: 'blur' }],
      }
    };
  },
  methods: {
    handleView(params) {
      // 显示密码
      this.showPw[params] = true;
    },
    handleHide(params) {
      // 隐藏密码
      this.showPw[params] = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          setPassword({
            old_password: this.ruleForm.old_password,
            password: this.ruleForm.password,
            confirm_password: this.ruleForm.confirm_password,
          }).then( (res) => {
            if (Number(res.code) === 20000) {
              this.$message({
                message: res.msg,
                type: "success",
              });

            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          console.log("error submit!!");
          this.$message({
            type: 'error',
            message: '表单校验失败，请检查！',
          });
          return false;
        }
      });
    }
  }

}
</script>
<style scoped>
.set-password-box {
  text-align: center;
}
.set-password-title {
  margin: 60px 0;
  font-size: 28px;
}
.set-password-ruleForm {
  width: 600px;
  margin: 0 auto;
}
.set-password-ruleForm /deep/ .el-input__inner{
  height: 56px;
  font-size: 16px;
}
.set-password-ruleForm /deep/ .el-icon-question {
  margin-right: 10px;
  font-size: 24px;
  vertical-align: bottom;
}
.set-password-ruleForm /deep/ .el-form-item {
  margin-bottom: 40px;
}
.set-password-ruleForm /deep/ .el-button--primary{
  margin: 20px 0 50px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  background-color: #008cd6;
  border-color: #008cd6;
}
.set-password-ruleForm /deep/ .el-icon-view {
  margin-right: 10px;
  font-size: 24px;
  vertical-align: bottom;
  cursor: pointer;
}
.u-icon-hidepassword {
  margin-top: 12px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  background: url("../assets/icon_hide_pw.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
}
</style>