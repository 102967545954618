import request from "@/utils/request.js";

/**
 * 手机验证码注册/登录
 * @param {*} phone_number 
 * @param {*} verify_code 
 * @returns 
 */
export function smsLogin(params) {
  return request({
    method: "post",
    contentType: 'form',
    url: "/Index/smsLogin",
    data: params,
  });
}

/**
 * 获取手机验证码
 * type短信类型: 1注册 2忘记密码 3更换手机 4其他
 * @param {*} phone_number 
 * @param {*} type 
 * @returns 
 */
export function getVerifyCode(params) {
  return request({
    method: "post",
    contentType: 'form',
    url: 'Index/getVerifyCode',
    data: params
  });
}

/**
 * 密码登录
 * @param {*} phone_number 
 * @param {*} password 
 * @returns 
 */
export function passwordLogin(params) {
  return request({
    method: "post",
    contentType: 'form',
    url: "/Index/passwordLogin",
    data: params
  });
}

/**
 * 忘记密码
 * @param {*} phone_number 
 * @param {*} password 
 * @param {*} verify_code 
 * @param {*} confirm_password 
 * @returns 
 */
export function forgetPassword(params) {
  return request({
    method: "post",
    url: "/Index/forgetPassword",
    data: params,
  });
}




/**
 * 修改密码
 * @param {*} password 
 * @param {*} confirm_password 
 * @returns 
 */
export function setPassword(params) {
  return request({
    method: "post",
    url: "/User/setPassword",
    data: params,
  });
}



